// Toggles
//
// Used in conjunction with global variables to enable certain theme features.

// Vendor
@import 'vendor/rfs';

// Deprecate
@import 'mixins/deprecate';

// Helpers
@import 'mixins/breakpoints';
@import 'mixins/image';
@import 'mixins/resize';
@import 'mixins/screen-reader';
@import 'mixins/reset-text';
@import 'mixins/text-truncate';
@import 'mixins/hover';

// Utilities
@import 'mixins/utilities';

// Components
@import 'mixins/alert';
@import 'mixins/buttons';
@import 'mixins/caret';
@import 'mixins/pagination';
@import 'mixins/lists';
@import 'mixins/list-group';
@import 'mixins/forms';
@import 'mixins/table-row';

// Skins
@import 'mixins/background-variant';
@import 'mixins/border-radius';
@import 'mixins/box-shadow';
@import 'mixins/gradients';
@import 'mixins/transition';

// Layout
@import 'mixins/clearfix';
@import 'mixins/grid-framework';
@import 'mixins/grid';

@mixin fontello-style() {
	font-family: 'fontello';
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: 0.2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: 0.2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin background-cover() {
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

@mixin input-text-style() {
	border: none;
	background-color: $white;
	border: 2px $white solid;
	color: $body-color;
	display: block;
	width: 100%;
	min-height: $spacer-lg;
	line-height: $spacer-lg;
	padding: $spacer-xs $spacer-md;
	@include border-radius(($spacer-lg), 0);
	@include box-shadow($input-box-shadow);
}
