.cart-overlay {
	background-color: rgba($gray-500, 0.6);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 799;
}

.cart-dropdown {
	position: fixed;
	top: 0;
	right: $spacer;
	width: 45%;
	min-width: 340px;
	max-width: 620px;
	padding: 0;
	z-index: 800;
	height: 100%;
	@include media-breakpoint-down(md) {
		width: 60%;
	}
	@include media-breakpoint-down(sm) {
		right: 2%;
		width: 96%;
		padding-left: $navbar-w-collapsed-sm;
	}
	&:before {
		width: 0;
		height: 0;
		content: ' ';
		display: block;
		position: absolute;
		top: 4.5rem;
		right: 6.4rem;
		z-index: 4;
		width: 0px;
		height: 0px;
		border-left: 0.6rem solid transparent;
		border-right: 0.6rem solid transparent;
		border-bottom: 0.6rem solid $white;
		@include media-breakpoint-down(sm) {
			top: 3.5rem;
		}
	}
	&:after {
		width: 0;
		height: 0;
		content: ' ';
		display: block;
		position: absolute;
		top: 4.4rem;
		right: 6.1rem;
		z-index: 1;
		width: 0px;
		height: 0px;
		border-left: 0.9rem solid transparent;
		border-right: 0.9rem solid transparent;
		border-bottom: 0.9rem solid $border-color;
		@include media-breakpoint-down(sm) {
			top: 3.4rem;
		}
	}
	> div {
		height: 100%;
		> div {
			margin-top: 5rem;
			background-color: $white;
			@include box-shadow($box-shadow-darker);
			@include border-top-radius($border-radius-sm);
			border: $border-color 1px solid;
			max-height: calc(100% - 10rem);
			overflow: auto;
			position: relative;
			z-index: 2;
			@include media-breakpoint-down(sm) {
				margin-top: 4rem;
			}
			.form-ui {
				padding-top: $spacer-lg;
			}
			> .button-link {
				margin-bottom: $spacer-sm;
				width: auto;
				margin-left: auto;
				margin-right: auto;
				margin-top: $spacer-xxs;
			}
		}
		> footer {
			width: 100%;
			// height: $spacer-xxxl +;
			background-color: $white;
			@include box-shadow($box-shadow-darker);
			@include border-bottom-radius($border-radius-sm);
			z-index: 3;
			position: relative;
			padding: $spacer-sm $spacer-sm;
			border: $border-color 1px solid;
			border-top: none;
			> div {
				display: flex;
				justify-content: space-between;
				@include media-breakpoint-down(sm) {
					display: block;
				}
				> * {
					width: 48%;
					height: $spacer-xxl;
					line-height: $spacer-xxl;
					@include media-breakpoint-down(sm) {
						width: 100%;
					}
					&:not(:first-child) {
						@include media-breakpoint-down(sm) {
							margin-top: $spacer-xs;
						}
					}
				}
				.button {
					padding-top: 0;
					padding-bottom: 0;
					> span,
					> i {
						margin-top: -2px;
					}
				}
			}
		}
		> b {
			display: block;
			background-color: transparent;
			height: 100%;
		}
	}
	ol.shop-list {
		div.adds {
			padding-left: 0;
			> em {
				@include media-breakpoint-down(sm) {
					font-size: $font-size-sm;
				}
			}
		}
		> li {
			.button-link-delete {
				top: $spacer * 1.1;
				@include media-breakpoint-down(xs) {
					top: $spacer + $spacer-xl;
				}
			}
		}
		& + textarea {
			display: block;
			border: none;
			border: $gray-300 2px solid;
			outline-style: none;
			padding: 0 $spacer-xs;
			box-shadow: none;
			line-height: $spacer-lg;
			height: $spacer-lg * 2;
			max-height: $spacer-lg * 2;
			resize: none;
			@include border-radius($border-radius-sm);
			width: calc(100% - 3.2rem);
			margin: 0 1.6rem;
			@include media-breakpoint-down(md) {
				width: calc(100% - 1.5rem);
				margin: 0 0.75rem;
			}
			&::placeholder {
				color: $gray-500;
			}
			&:focus {
				border-color: $primary;
			}
			& + .form-group {
				margin-bottom: $spacer;
				max-width: 290px;
				float: right;

				@include media-breakpoint-down(md) {
					// max-width: 100%;
				}
				@include media-breakpoint-down(sm) {
					float: none;
					margin-left: auto;
					margin-right: auto;
					// max-width: 100%;
				}
			}
		}
	}
}

ol.shop-list {
	list-style: none;
	padding: 0;
	margin: 0;
	// padding-bottom: $spacer-xxxl;
	> li {
		border-bottom: $border-color 1px solid;
		padding: $spacer $spacer-md;
		padding-right: $spacer-xxl * 1.2;
		position: relative;
		@include media-breakpoint-down(sm) {
			// padding: $spacer-xs $spacer-sm;
			padding-right: $spacer-xl * 1.6;
		}
		&:after {
			content: ' ';
			display: block;
			clear: both;
		}
		&:last-child {
			border-bottom: none;
		}
		&:hover {
			background-color: $gray-100;
		}
		.button-link-delete {
			display: inline-block;
			float: right;
			width: auto;
			cursor: pointer;
			position: absolute;
			top: $spacer * 1.15;
			right: $spacer;
			@include media-breakpoint-down(xs) {
				right: $spacer-xs;
				top: ($spacer-xxl + $spacer) * 1.02;
			}
			i {
				font-size: 24px;
				@include media-breakpoint-down(xs) {
					font-size: 24px;
				}
			}
		}
		blockquote {
			display: flex;
			justify-content: space-between;
			align-content: center;
			align-items: center;
			margin: 0;
			margin-bottom: $spacer-xs;
			> div {
				display: flex;
				align-items: center;
				padding-right: $spacer-sm;
				@include media-breakpoint-down(xs) {
					display: block;
				}
				.badge {
					margin-left: $spacer-sm;
					line-height: $spacer-lg;
					padding: 0 $spacer;
					@include font-size($font-size-sm);
					@include border-radius($border-radius);
					@include media-breakpoint-down(xs) {
						margin-left: 0;
						@include font-size($font-size-sm * 0.9);
						line-height: $spacer-md;
						padding: 0 $spacer-sm;
					}
				}
				span.img {
					margin-right: $spacer-sm;
					width: $spacer-xl;
					height: $spacer-xl;
					@include media-breakpoint-down(sm) {
					}
					@include media-breakpoint-down(xs) {
						width: $spacer-xl * 2;
						height: $spacer-xl * 2;
						margin-bottom: $spacer-xs;
					}
				}
				strong {
					// @include font-size($font-size-base);
					@include media-breakpoint-down(sm) {
						@include font-size($font-size-sm);
						line-height: 1.4;
						display: block;
					}
				}
			}
			> span.price {
				position: relative;
				padding-right: $spacer-md;
				@include media-breakpoint-down(sm) {
					text-align: right;
				}
				i {
					position: absolute;
					top: 5px;
					right: 0;
					font-size: 15px;
					color: $gray-500;
					@include media-breakpoint-down(sm) {
					}
				}
			}
		}
		&.item-paid {
			background-color: lighten($success, 38%) !important;
			padding-top: $spacer-xxl;
			> .badge {
				position: absolute;
				top: 0;
				left: 0;
				line-height: $spacer-md;
				padding: $spacer-xxs $spacer-sm;
				@include font-size($font-size-sm);
				width: 100%;
			}
			.button-link-delete {
				margin-top: $spacer-lg;
			}
			&:last-child {
				margin-bottom: $spacer-sm;
			}
		}
	}
	&.shop-list-table {
		div.adds {
			padding-right: 0;
			padding-left: $spacer-xl + $spacer-sm;
			@include media-breakpoint-down(sm) {
				padding-left: 0;
				padding-top: $spacer-xs;
			}
			> div {
				@include media-breakpoint-down(sm) {
					margin-top: 0;
				}
				> label {
					@include media-breakpoint-down(sm) {
						margin-bottom: 0;
					}
				}
			}
		}
		> li {
			background-color: transparent;
		}
	}
}

.badge {
	display: inline-block;
	line-height: $spacer-xl;
	padding: 0.2rem 0;
	background-color: $primary;
	color: $white;
	font-weight: $font-weight-bold;
	@each $color, $value in $theme-colors {
		&.badge-#{$color},
		&.#{$color} {
			background-color: $value;
			color: color-yiq($value);
		}
	}
	// &.danger {
	// 	background-color: $red;
	// }
	// &.warning {
	// 	background-color: $yellow;
	// }
	// &.success {
	// 	background-color: $green;
	// }
	// &.orange {
	// 	background-color: $orange;
	// }
	// &.gray {
	// 	background-color: $gray-500;
	// }
	@include media-breakpoint-down(sm) {
		@include font-size($font-size-sm);
		line-height: 1.4;
	}
}

div.custom-notes {
	// padding: $spacer;
}

.custom-notes {
	background-color: lighten($primary, 40%);
	h4 {
		color: $gray-900;
		text-align: left;
		background-color: lighten($primary, 35%);
		padding: $spacer-xs $spacer-md;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		> li {
			padding-top: $spacer-xs;
			position: relative;
			padding-left: $spacer-xxxl * 1.1;
			display: inline-block;
			@include media-breakpoint-down(sm) {
				text-align: left;
			}
			> * {
				display: inline-block;
				vertical-align: middle;
			}
			> i {
				color: $primary;
				margin-right: $spacer-xs;
				margin-left: $spacer-sm;
				position: absolute;
				top: $spacer-xs;
				left: $spacer-xxs;
			}
			> span {
				font-weight: $font-weight-normal;
				color: $gray-900;
				display: block;
				padding-right: $spacer-md;
				@include media-breakpoint-down(sm) {
					text-align: left;
				}
			}
			&:first-child:not(:last-child) {
				padding-bottom: $spacer-xs;
			}
			&:not(:first-child) {
				// border-top: lighten($yellow, 45%) 2px solid;
			}

			&:last-child {
				padding-bottom: $spacer-xs;
			}
		}
	}
}
