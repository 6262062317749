body {
	// background-color: $dark;
	.dashboard > .main {
		// background-color: $body-bg;
		// overflow: hidden;
		padding-bottom: 80px;
	}
}

#navbar {
	// @include transition(0.3s top ease);
	width: $navbar-w;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9998;
	background-color: $dark;
	height: 100%;
	overflow: visible;
	color: $white;
	@include box-shadow($box-shadow);
	@include media-breakpoint-navbar() {
		width: $navbar-w-sm;
	}
	a {
		color: $white;
	}
	> div {
		min-height: 100%;
		position: relative;
		padding-bottom: $spacer-xxxl * 2;
		@include media-breakpoint-navbar() {
			padding-bottom: $spacer-xxl * 2;
		}
		> div {
			background-color: $dark;
		}
	}
	.status {
		text-align: center;
		font-size: $font-size-xs;
		font-weight: $font-weight-bold;
		overflow: hidden;
		line-height: $spacer;
		@include media-breakpoint-navbar() {
			line-height: $spacer-sm;
		}
		&.danger p {
			background-color: $red;
		}
		&.success p {
			background-color: $green;
		}
		p {
			border-radius: 0.2rem;
			margin: $spacer-xxs;
			padding: $spacer-xxs $spacer;
			@include media-breakpoint-navbar() {
				margin: 0;
				border-radius: 0;
			}
		}
	}
	div.brand {
		background-color: $gray-800;
		padding-left: $spacer-xxxl + ($spacer-xs * 2);
		position: relative;
		height: $spacer-xxxl + ($spacer-xs * 2);
		display: flex;
		align-items: center;
		@include media-breakpoint-navbar() {
			padding-left: $spacer-xxl;
			height: $spacer-xxl;
		}
		img,
		i.mi {
			height: $spacer-xxxl;
			line-height: $spacer-xxxl;
			width: $spacer-xxxl;
			background-color: $gray-800;
			margin: $spacer-xs;
			margin-right: $spacer;
			border-radius: 0.2rem;
			vertical-align: middle;
			position: absolute;
			top: 0;
			left: 0;
			@include media-breakpoint-navbar() {
				border-radius: 0;
				margin: 0;
				margin-right: $spacer-sm;
				height: $spacer-xxl;
				line-height: $spacer-xxl;
				width: $spacer-xxl;
			}
		}
		i.mi {
			text-align: center;
			font-size: 40px;
			margin-right: $spacer-sm;
			@include media-breakpoint-navbar() {
				margin-right: $spacer-xxs;
				font-size: 30px;
			}
		}
		strong {
			font-family: $headings-font-family;
			font-style: $headings-font-style;
			font-weight: $headings-font-weight;
			line-height: $headings-line-height;
			padding-right: $spacer-sm;
			font-size: $font-size-base * 0.9;
			@include media-breakpoint-navbar() {
				font-size: $font-size-sm;
				padding-right: $spacer-xs;
				padding-left: $spacer-xs;
			}
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			height: $spacer-xxl;
			line-height: $spacer-xxl;
			border-bottom: $gray-800 0px solid;
			@include media-breakpoint-navbar() {
				height: $spacer-lg;
				line-height: $spacer-lg;
				font-size: $font-size-sm;
			}
			a {
				display: block;
				padding: 0 1.3rem;
				text-decoration: none;
				position: relative;
				@include media-breakpoint-navbar() {
					padding: 0 0.9rem;
				}
				i {
					color: $gray-600;
					vertical-align: middle;
					margin-right: $spacer-sm;
					@include media-breakpoint-navbar() {
						margin-right: $spacer-xs;
						font-size: 16px;
					}
				}
				span {
					vertical-align: middle;
				}
				&:hover {
					background-color: $gray-800;
					i {
						color: $white;
					}
				}
				&:focus,
				&:active {
					background-color: $gray-600;
					i {
						color: $white;
					}
				}
				pre {
					background-color: $red;
					color: $white;
					line-height: 22px;
					margin: 0;
					margin-top: -(11px);
					padding: 0 $spacer-xs;
					position: absolute;
					top: 50%;
					right: $spacer-xs;
					border-radius: 0.8rem;
					font-family: $font-family-base;
					font-weight: $font-weight-bold;
					font-size: 11px;
					@include media-breakpoint-navbar() {
						font-size: 8px;
						line-height: 20px;
						margin-top: -10px;
						right: 4px;
						padding: 0 6px;
					}
				}
			}
			&.active {
				a {
					background-color: $primary;
					&:hover,
					&:focus,
					&:active {
						background-color: lighten($primary, 10%);
					}
					i {
						color: lighten($primary, 68%);
					}
				}
			}
		}
	}
	button.collapse {
		height: $spacer-xxxl;
		padding: 0;
		overflow: hidden;
		padding: 0;
		width: 100%;
		border: none;
		color: $white;
		outline-style: none;
		background-color: $dark;
		text-align: right;
		position: absolute;
		bottom: $spacer-xxxl;
		left: 0;
		width: 100%;
		@include media-breakpoint-navbar() {
			bottom: $spacer-xl;
			height: $spacer-xl;
		}
		&:hover,
		&:active,
		&:focus {
			// background-color: $gray-700;
			i {
				color: $white;
			}
		}
		i {
			display: inline-block;
			width: $navbar-w-collapsed;
			height: $spacer-xxxl;
			line-height: $spacer-xxxl;
			border-bottom: $gray-800 0px solid;
			text-align: center;
			color: $gray-500;
			@include media-breakpoint-navbar() {
				height: $spacer-xl;
				line-height: $spacer-xl;
				width: $navbar-w-collapsed-sm;
			}
		}
	}
	button.user {
		position: absolute;
		bottom: 0;
		left: 0;
		height: $spacer-xxxl;
		padding: 0;
		overflow: hidden;
		padding-left: $spacer-xxxl;
		width: 100%;
		border: none;
		color: $white;
		outline-style: none;
		background-color: lighten($dark, 4%);
		&:hover,
		&:active,
		&:focus {
			background-color: $gray-700;
		}
		@include media-breakpoint-navbar() {
			height: $spacer-xl;
		}
		i {
			position: absolute;
			top: 0;
			left: 0;
			color: $gray-600;
			text-align: center;
			width: $navbar-w-collapsed;
			@include media-breakpoint-navbar() {
				width: $navbar-w-collapsed-sm;
			}
		}
		span {
			width: 90%;
			overflow: hidden;
			@include text-truncate();
			@include media-breakpoint-navbar() {
				width: 80%;
			}
		}
		i,
		span {
			height: $spacer-xxxl;
			line-height: $spacer-xxxl;
			display: block;
			vertical-align: middle;
			@include media-breakpoint-navbar() {
				height: $spacer-xl;
				line-height: $spacer-xl;
			}
		}
		@include media-breakpoint-navbar() {
			padding-left: $spacer-xl;
		}
	}
}

#root {
	> #main {
		> .dashboard {
			&.has-navbar {
				padding-left: $navbar-w;
				@include media-breakpoint-navbar() {
					// padding-left: 0;
					// margin-left: $navbar-w-sm;
					padding-left: $navbar-w-collapsed-sm;
					margin-left: 0;
				}
				&.navbar-closed {
					margin-left: 0;
					padding-left: $navbar-w-collapsed;
					@include media-breakpoint-navbar() {
						margin-left: 0;
						padding-left: $navbar-w-collapsed-sm;
					}
					#navbar {
						width: $navbar-w-collapsed;
						@include media-breakpoint-navbar() {
							width: $navbar-w-collapsed-sm;
						}
						.status {
							p {
								padding-left: 0;
								padding-right: 0;
								font-size: 10px;
								@include media-breakpoint-navbar() {
									font-size: 8px;
								}
							}
						}
						div.brand {
							text-align: center;
							img,
							i.mi {
								// margin-left: auto;
								// margin-right: auto;
								margin-right: 0;
								// margin-left: 0;
							}
							strong {
								display: none;
							}
						}
						ul {
							li {
								position: relative;
								a {
									span {
										display: none;
									}
								}
							}
						}
						button.user {
							span {
								display: none;
							}
						}
					}
					> .main {
						.topbar {
							padding-left: ($navbar-w-collapsed) !important;
							@include media-breakpoint-navbar() {
								padding-left: ($navbar-w-collapsed-sm) !important;
							}
							@include media-breakpoint-down(sm) {
								padding-left: 0 !important;
							}
						}
					}
				}
				&.navbar-opened {
					#navbar {
						@include media-breakpoint-navbar() {
							box-shadow: 0 0 3rem rgba($dark, 0.7);
						}
					}
					> .main {
						@include media-breakpoint-navbar() {
							opacity: 0.4;
						}
						.topbar {
							padding-left: ($navbar-w) !important;
							@include media-breakpoint-down(md) {
								padding-left: 0 !important;
							}
							@include media-breakpoint-navbar() {
								// padding-left: ($navbar-w-sm) !important;
								// padding-left: 0 !important;
							}
						}
					}
				}
			}
		}
	}
}
