button,
label.button {
	font-size: $font-size-base * 0.9;
	@include media-breakpoint-down(md) {
		font-size: $font-size-md * 0.93;
	}
	@include media-breakpoint-down(sm) {
		font-size: $font-size-md * 0.82;
	}
}

.button {
	display: block;
	width: 100%;
	@include border-radius(0.2rem, 0);
	@include box-shadow($input-box-shadow);
	// color: $white;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	// background-color: $primary;
	border: 2px transparent solid;
	min-height: $spacer-lg;
	line-height: $spacer-lg;
	padding: $spacer-xs $spacer-md;
	font-family: $headings-font-family;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	@include transition(all 0.3s ease-out);
	@include media-breakpoint-down(md) {
		min-height: $spacer;
		line-height: $spacer;
		padding: $spacer-sm $spacer-sm;
	}
	@include media-breakpoint-down(sm) {
		padding: $spacer-xs $spacer-sm;
	}
	&:hover {
		// color: $white;
		text-decoration: none;
		// background-color: darken($primary, 8%);
		// border-color: darken($primary, 8%);
	}
	&:focus,
	&.focus {
		outline: 0;
		// background-color: darken($primary, 14%);
		// border-color: $primary;
	}
	&.disabled,
	&:disabled {
		@include box-shadow(none);
		// background-color: lighten($primary, 8%);
		// border-color: lighten($primary, 10%);
		// color: lighten($primary, 25%);
	}
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		// @include box-shadow($btn-active-box-shadow);
		// background-color: darken($primary, 14%);
		// border-color: $primary;

		&:focus {
			// box-shadow: 0 0 5px $primary;
		}
	}
	> i {
		font-size: $font-size-base;
		@include media-breakpoint-down(md) {
			font-size: $font-size-md;
		}
	}
	> span,
	> i {
		display: inline-block;
		vertical-align: middle;
		& + span {
			margin-left: $spacer-sm;
			@include media-breakpoint-down(md) {
				margin-left: $spacer-xs;
			}
			@include media-breakpoint-down(xs) {
				margin-left: 3px;
			}
		}
	}
	> span {
		& + i {
			margin-left: $spacer-sm;
		}
	}
	&.button-icon-on-r {
		> i {
			margin-right: 0;
		}
		> span {
			margin-left: 0;
			margin-right: $spacer-sm;
		}
	}
	&.inline {
		display: inline-block;
		width: auto;
	}
	&.button-small {
		@include font-size($font-size-sm);
		padding: $spacer-xxs $spacer-sm;
	}

	// &.button-dark {
	// 	background-color: $dark;
	// 	border: 2px $dark solid;
	// }
	// &.button-secondary {
	// 	background-color: $primary;
	// 	border: 2px $primary solid;
	// }
	// &.button-danger {
	// }
	&.button-responsive {
		// @include media-breakpoint-down(sm) {
		// 	padding-left: $spacer-sm !important;
		// 	padding-right: $spacer-sm !important;
		// }
		// > span {
		// 	@include media-breakpoint-down(sm) {
		// 		display: none !important;
		// 	}
		// }
	}
	@each $color, $value in $theme-colors {
		&.button-#{$color} {
			background-color: $value;
			border: 2px $value solid;
			color: color-yiq($value);
			&:hover {
				background-color: darken($value, 4%);
			}
			&:focus,
			&.focus {
				background-color: darken($value, 8%);
				border-color: darken($value, 8%);
				// color: color-yiq(darken($value, 8%));
			}
			&.disabled,
			&:disabled {
				background-color: lighten($value, 8%);
				border-color: lighten($value, 8%);
				opacity: 0.7;
				// color: color-yiq($value);
			}
			&:not(:disabled):not(.disabled):active,
			&:not(:disabled):not(.disabled).active {
				// background-color: transparent;
				// border-color: darken($value, 14%);
				// color: darken($value, 14%);
				background-color: darken($value, 10%);
				border-color: darken($value, 19%);
				box-shadow: 0 2px 8px rgba($value, 0.6);
				&:focus {
					box-shadow: 0 2px 8px rgba(darken($value, 8%), 0.7);
				}
			}
		}
		&.button-#{$color}-outline {
			background: transparent;
			color: $value;
			border-color: $value;
			&:hover {
				background: transparent;
				border-color: darken($value, 6%);
				color: darken($value, 6%);
				box-shadow: 0 0 8px lighten($value, 10%);
			}
			&:focus,
			&.focus {
				border-color: darken($value, 8%);
				color: darken($value, 6%);
				box-shadow: 0 0 8px lighten($value, 6%);
				background-color: lighten($value, 29%);
			}
			&.disabled,
			&:disabled {
				// background-color: transparent;
				border-color: lighten($value, 6%);
				color: lighten($value, 8%);
				opacity: 0.7;
			}
			&.pop-it {
				box-shadow: 0 0 8px $value;
				// background-color: darken($value, 14%);
				// color: color-yiq(darken($value, 14%));
			}
			&:not(:disabled):not(.disabled):active,
			&:not(:disabled):not(.disabled).active {
				// background-color: transparent;
				border-color: darken($value, 8%);
				color: darken($value, 8%);
				box-shadow: 0 0 8px $value;
				&:focus,
				&.pop-it {
					box-shadow: 0 0 8px $value;
					background-color: lighten($value, 26%);
					// background-color: darken($value, 14%);
					// color: color-yiq(darken($value, 14%));
				}
			}
		}
	}
	// &.button-light {
	// 	background-color: $light;
	// 	border: 2px $light solid;
	// 	color: $body-color;
	// 	&:hover {
	// 		background-color: $light;
	// 		color: $headings-color;
	// 	}
	// 	&:focus,
	// 	&.focus {
	// 		border-color: darken($body-color, 14%);
	// 		color: darken($body-color, 14%);
	// 	}
	// 	&.disabled,
	// 	&:disabled {
	// 		background-color: transparent;
	// 		border-color: lighten($body-color, 8%);
	// 		color: lighten($body-color, 8%);
	// 	}
	// 	&:not(:disabled):not(.disabled):active,
	// 	&:not(:disabled):not(.disabled).active {
	// 		background-color: transparent;
	// 		border-color: darken($body-color, 14%);
	// 		color: darken($body-color, 14%);
	// 		&:focus {
	// 			box-shadow: 0 0 8px $body-color;
	// 		}
	// 	}
	// }
	&.button-link {
		@include box-shadow(none);
		background: transparent;
		padding: 0;
		&:hover {
			color: darken($primary, 10%);
		}
		&:focus,
		&.focus {
		}
		&.disabled,
		&:disabled {
		}
		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			background: transparent;
			color: darken($primary, 20%);
			&:focus {
				@include box-shadow(none);
			}
		}
		&.button-link-sm {
			@include font-size($font-size-sm);
		}
	}
}

.button-link {
	background: transparent;
	border: none;
	text-align: center;
	color: $primary;
	display: block;
	width: 100%;
	font-family: $font-family-sans-serif;
	text-transform: none;
	&:hover,
	&:focus,
	&.focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		outline-style: none;
		color: darken($primary, 5%);
	}
	&.button-link-delete {
		color: $danger;
		&:hover {
			color: darken($danger, 10%);
		}
		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			background: transparent;
			color: darken($danger, 20%);
		}
	}
	&.button-link-gray {
		color: $gray-600;
		&:hover {
			color: darken($gray-600, 10%);
		}
		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			background: transparent;
			color: darken($gray-600, 20%);
		}
	}
}
