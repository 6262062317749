#root {
	> #main {
		> .dashboard {
			&.items-management {
				.indicators-ui {
					padding: 0;
				}
				> .main {
					padding-bottom: $spacer-lg;
					.topbar {
						@include media-breakpoint-down(sm) {
							justify-content: space-between;
						}
						> .tabs {
							@include media-breakpoint-down(sm) {
								order: 0;
								width: 10%;
							}
						}
						div.actions {
							@include media-breakpoint-down(sm) {
								width: 90%;
								text-align: right;
							}
						}
					}
					> header {
						padding: $spacer-md $spacer-lg 0;
						h1 {
							color: $gray-900;
							font-size: 1.4em;
						}
						p {
							margin-bottom: $spacer-xs;
						}
					}
					.row {
						display: flex;
						flex-wrap: wrap;
						padding: 0 $spacer-sm;
						margin-bottom: $spacer-lg;
						> div {
							padding: 0 $spacer-sm;
							& > div {
								@include media-breakpoint-down(xs) {
									margin-top: $spacer;
								}
							}
							&.col-6 {
								width: 50%;
								&.order-sm-2 {
									@include media-breakpoint-down(xs) {
										order: 2;
									}
								}
								@include media-breakpoint-down(xs) {
									width: 100%;
								}
							}
						}
					}
					.indicators-ui {
						margin: 0;
						> div {
							display: block;
							padding: 0;
							> div {
								margin-top: $spacer-sm;
								&:first-child {
									margin-top: 0;
								}
								> div {
									margin-top: 0;
									> small {
										display: block;
										text-align: left;
									}
									> div {
										margin-bottom: 0;
										padding-top: $spacer-xs;
										border-bottom: none;
										& + div {
											margin-top: $spacer-xs;
											border-top: $gray-400 1px dotted;
										}
									}
								}
							}
						}
					}
					.registrations {
						padding: 0 $spacer-lg;
						nav {
							display: flex;
							width: 100%;
							justify-content: space-between;
							align-items: center;
							@include media-breakpoint-down(xs) {
								align-items: flex-start;
							}
						}
						div.actions {
							margin-bottom: 0;
							@include media-breakpoint-down(sm) {
								min-width: 230px;
								text-align: right;
							}
						}
						ul.tabs {
							padding-left: 0;
							@include media-breakpoint-down(sm) {
								flex-wrap: wrap;
								justify-content: flex-start;
							}
							@include media-breakpoint-down(xs) {
								display: block;
							}
							> li {
								@include media-breakpoint-down(sm) {
									border-bottom-width: 2px;
									margin-bottom: $spacer-sm;
								}
								@include media-breakpoint-down(xs) {
									margin-bottom: $spacer-sm;
								}
								> a {
									line-height: $spacer-xl;
									@include media-breakpoint-down(sm) {
										line-height: $spacer-lg;
									}
									@include media-breakpoint-down(lg) {
										font-size: 0.8em;
									}
									@include media-breakpoint-down(md) {
										font-size: 0.72em;
									}
									@include media-breakpoint-down(xs) {
										font-size: 0.8em;
									}
								}
							}
						}
						.table-ui {
							padding-left: 0;
							padding-right: 0;
							> div {
								> table {
									th,
									td {
										@include media-breakpoint-down(sm) {
											text-align: left;
										}
									}
									td {
										p {
											max-width: 100%;
											> strong {
												@include media-breakpoint-down(sm) {
													display: block;
												}
											}
										}
										small {
											cursor: pointer;
											display: inline-block;
										}
										hr {
											border: none;
											background: none;
											margin: $spacer-xs 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
