div.alert {
	background-color: lighten($primary, 40%);
	color: $gray-800;
	text-align: center;
	padding: $spacer;
	i {
		color: $primary;
		display: inline-block;
		vertical-align: middle;
		margin-right: $spacer;
	}
	p {
		display: inline-block;
		margin: 0;
		vertical-align: middle;
		& + p {
			padding-left: $spacer;
		}
	}
	.button {
		display: inline-block;
		vertical-align: middle;
		width: auto;
		padding: $spacer-xxxs $spacer;
		margin-left: $spacer;
	}
	@each $color, $value in $theme-colors {
		&.alert-#{$color} {
			background-color: lighten($value, 40%);
			i {
				color: $value;
			}
		}
	}
}
