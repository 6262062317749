.form-ui {
	background-color: $white;
	@include border-radius($border-radius);
	@include box-shadow($box-shadow);
	display: flex;
	flex-wrap: wrap;
	@include media-breakpoint-down(md) {
		@include border-radius($border-radius-md);
	}
	> * {
		width: 100%;
	}
	.logo {
		width: 8rem;
		height: 8rem;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-top: -$spacer-xl;
		background-image: url(../../img/icon.png);
		background-size: 100% 100%;
		& + h1 {
			padding-top: $spacer;
		}
		@include media-breakpoint-down(sm) {
			margin-top: $spacer;
		}
	}
	h1 {
		// border-bottom: $gray-200 1px solid;
		text-align: center;
		color: $headings-color;
		@include font-size($h4-font-size * 0.95);
		padding-top: $spacer-xl;
		@include media-breakpoint-down(md) {
			padding-top: $spacer-md;
		}
		+ em {
			// margin-top: $spacer;
		}
		@include media-breakpoint-down(md) {
			@include font-size($h4-font-size * 0.9);
		}
		@include media-breakpoint-down(sm) {
			@include font-size($h4-font-size * 0.8);
		}
	}
	.img-container {
		text-align: center;

		margin-top: $spacer;
		margin-left: $spacer-lg;
		margin-right: $spacer-lg;

		@include media-breakpoint-down(md) {
			margin-top: $spacer;
			margin-left: $spacer-sm;
			margin-right: $spacer-sm;
		}
		> div {
			background-color: darken($gray-100, 0%);
			border: $gray-200 1px solid;
			@include border-radius($border-radius-sm);
			@include box-shadow($box-shadow-sm);
			overflow: hidden;
			img {
				width: 100%;
				height: auto;
			}
		}
		&.img-logo {
			> div {
				max-width: 280px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	> ol {
		margin-top: $spacer;
		margin-left: $spacer-lg;
		margin-right: $spacer-lg;

		@include media-breakpoint-down(md) {
			margin-top: $spacer;
			margin-left: $spacer-sm;
			margin-right: $spacer-sm;
		}
	}
	> em {
		font-style: normal;
	}
	> em,
	> p {
		@include font-size($font-size-lg);
		padding: $spacer-md $spacer-lg $spacer-xs;
		text-align: center;
		display: block;
		margin: $spacer-sm 0 0 0;
	}
	> p.info {
		background-color: lighten($primary, 38%);
		border-radius: $border-radius;
		margin-left: $spacer-lg;
		margin-right: $spacer-lg;
		padding: $spacer-md $spacer-xl !important;
		color: $gray-900;
		i {
			color: $primary;
			vertical-align: middle;
			display: inline-block;
			margin-right: $spacer-sm;
			margin-top: -2px;
		}
	}
	> .card.vcard {
		margin-left: $spacer-lg;
		margin-right: $spacer-lg;
		margin-bottom: $spacer;
	}
	hr {
		margin-bottom: 0;
		background-color: $gray-500;
	}
	.info-card {
		padding-left: $spacer-lg;
		padding-right: $spacer-lg;
		margin-bottom: $spacer;
		& + footer {
			padding-top: $spacer-xs;
		}
	}
	.row {
		display: flex;
		flex-wrap: wrap;
		padding: 0 ($form-gutter-width / 2);
		margin-top: $spacer-lg;
		> div {
			width: 50%;
			padding: 0 ($form-gutter-width / 2);
			.form-group {
				padding-left: 0;
				padding-right: 0;
			}
			&.col-left {
				width: 40%;
			}
			&.col-right {
				width: 60%;
			}
		}
	}
	h3 {
		margin-top: $spacer;
		padding-left: $spacer-lg;
		padding-right: $spacer-lg;
		position: relative;
		text-align: center;
		color: $gray-400;
		text-transform: uppercase;
		@include font-size($font-size-sm);
	}
	> fieldset {
		padding-top: 0;
		padding-bottom: $spacer-lg;
		// border: $gray-200 $border-width solid;
		margin-left: $spacer-lg;
		margin-right: $spacer-lg;
		@include box-shadow($box-shadow-sm);
		margin-top: 0;
		position: relative;
		// z-index: 5;
		background-color: $white;
		@include media-breakpoint-down(md) {
			margin-left: $spacer;
			margin-right: $spacer;
		}
		& + hr {
			background: transparent;
		}
		.fieldset-header {
			background-color: $gray-100;
			padding: $spacer $spacer-lg;
			margin-bottom: $spacer-lg;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			@include media-breakpoint-down(md) {
				padding: $spacer-sm $spacer-md;
			}
			h2 {
				color: $headings-color;
				@include font-size($font-size-lg);
				@include media-breakpoint-down(md) {
					@include font-size($font-size-base * 0.92);
				}
			}
			.button-link {
				width: auto;
			}
		}
		.fieldset-body {
			display: flex;
			flex-wrap: wrap;
			> * {
				width: 100%;
				display: block;
			}
			> [class*='cols'] {
				width: 50%;
				&.colside-left {
					padding-right: $form-gutter-width / 2;
				}
				&.colside-right {
					padding-left: $form-gutter-width / 2;
				}
				&.colside-center {
					padding-left: $form-gutter-width / 2;
					padding-right: $form-gutter-width / 2;
				}
				&.cols-8 {
					width: 8.333333% * 8;
				}
				&.cols-4 {
					width: 8.333333% * 4;
				}
			}
			fieldset {
				margin-top: $form-gutter-width;
				text-align: center;
				> .button {
					display: inline-block;
					width: auto;
					margin-top: $form-gutter-width;
					margin-left: auto;
					margin-right: auto;
				}
				@include media-breakpoint-down(sm) {
					border-top: $gray-300 $border-width dotted;
					padding-top: $spacer;
				}
			}
			.adder {
				margin-top: $form-gutter-width;
				margin-left: $spacer-lg;
				margin-right: $spacer-lg;
				@include media-breakpoint-down(md) {
					margin-left: $spacer-sm;
					margin-right: $spacer-sm;
				}
			}
		}
		.close {
			position: absolute;
			top: -$spacer-sm;
			right: -$spacer-sm;
		}
		& + fieldset {
			@include border-top-radius(0);
			border-top: $border-color $border-width dashed;
			&.fieldset-image-single {
				border-top: none;
			}
		}
		> .button {
			margin-top: $spacer;
		}
		&.first-child {
			@include border-top-radius($border-radius);
			margin-top: $spacer;
			@include media-breakpoint-down(md) {
				margin-top: $spacer-sm;
				@include border-top-radius($border-radius-md);
			}
			// .close {
			// 	display: none;
			// }
			// .button-link-delete {
			// 	display: none;
			// }
		}
		&.last-child {
			@include border-bottom-radius($border-radius);
			@include media-breakpoint-down(md) {
				@include border-bottom-radius($border-radius-md);
			}
		}
		& + .adder {
			padding-top: $spacer + 0.5rem;
			margin-top: -0.5rem;
			@include border-top-radius(none);
			&.adder-single {
				padding-top: $spacer;
				margin-top: 0;
			}
		}
		&.fieldset-image {
			padding-bottom: 0;
			text-align: center;
			background-color: $gray-200;
			background-image: url('../../img/bgtiles.png');
			.fieldset-header {
				margin-bottom: 0;
			}
			> img {
				max-width: 100%;
				height: auto;
				margin-left: auto;
				margin-right: auto;
			}
			.ReactCrop {
				width: 100%;
				height: auto;
				display: block;
				.ReactCrop__image {
					height: auto;
					max-width: 100%;
					max-height: none;
				}
			}
			&.fieldset-image-single {
				margin-bottom: $spacer-md;
				@include media-breakpoint-down(md) {
					margin-bottom: $spacer-sm;
				}
			}
		}
	}
	> .adder {
		padding: $spacer;
		background-color: $gray-200;
		margin-left: $spacer-lg;
		margin-right: $spacer-lg;
		@include border-radius($border-radius-sm);
		margin-top: $spacer;
		@include media-breakpoint-down(md) {
			margin-left: $spacer-sm;
			margin-right: $spacer-sm;
		}
		@include media-breakpoint-down(sm) {
			padding: $spacer-xs;
		}
		> .button {
			margin-left: auto;
			margin-right: auto;
			width: auto;
			display: block;
			min-width: 320px;
			width: 66%;
			margin-bottom: 0;
			@include media-breakpoint-down(sm) {
				min-width: 0;
				width: 100%;
			}
		}
		&.adder-single {
			padding: $spacer;
			@include media-breakpoint-down(sm) {
				padding: $spacer-xs;
			}
		}
		&.has-popover {
			position: relative;
			@include media-breakpoint-down(sm) {
				padding-right: $spacer-xxl;
			}
			.popover {
				position: absolute;
				top: 50%;
				right: $spacer;
				margin-top: -$spacer-sm;
				@include media-breakpoint-down(sm) {
					right: $spacer-xs;
				}
			}
		}
	}
	.close {
		background-color: $body-color;
		color: $white;
		border: none;
		width: $spacer-xl;
		height: $spacer-xl;
		border-radius: 50%;
		padding: 0;
		i {
			width: $spacer-xl;
			height: $spacer-xl;
			line-height: $spacer-xl;
		}
	}
	input[type='file'] {
		position: fixed;
		top: -999em;
		left: -999em;
	}
	.alert {
		margin-top: $spacer;
		padding-left: $spacer-lg;
		padding-right: $spacer-lg;
		@include media-breakpoint-down(md) {
			margin-top: $spacer;
			padding-left: $spacer-sm;
			padding-right: $spacer-sm;
		}
		@include media-breakpoint-down(sm) {
			margin-top: $spacer !important;
		}
	}
	> .group {
		background-color: $gray-200;
		border: $gray-300 $border-width solid;
		border-radius: $border-radius-md;
		margin: 0 $spacer-lg;
		padding-top: $spacer-lg;
		padding-bottom: $spacer-sm;
		@include media-breakpoint-down(md) {
			margin: 0 $spacer-sm;
		}
		padding-left: $spacer-lg;
		@include media-breakpoint-down(md) {
			padding-left: $spacer-sm;
		}
		& + .group {
			margin-top: $spacer-lg;
			@include media-breakpoint-down(md) {
				margin-top: $spacer-sm;
			}
		}
		input.text-control {
			width: calc(100% - 1.6rem);
			@include media-breakpoint-down(md) {
				width: calc(100% - 0.75rem);
			}
		}
		.subs {
			// padding-left: $spacer-lg;
			// @include media-breakpoint-down(md) {
			// 	padding-left: $spacer-sm;
			// }
			input.text-control {
				line-height: 2.3rem;
				font-size: $font-size-sm;
				@include media-breakpoint-down(sm) {
					line-height: 1.6rem;
				}
			}
		}
		.form-group-check {
			padding-left: 0 !important;
			padding-right: 0 !important;
			padding-bottom: $spacer-xxs;
			label:not(.button) {
				padding-left: 0 !important;
			}
			> .form-row {
				> div {
					padding-left: 0 !important;
					padding-right: 0 !important;
					justify-content: flex-start;
				}
			}
		}
	}
	.row-group {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: $spacer;
		> .col-10 {
			width: (8.333333333333333% * 10);
		}
		> .col-9 {
			width: (8.333333333333333% * 9);
		}
		> .col-8 {
			width: (8.333333333333333% * 8);
		}
		> .col-6 {
			width: (8.333333333333333% * 6);
		}
		> .col-2 {
			width: (8.333333333333333% * 2);
		}
		> .col-3 {
			width: (8.333333333333333% * 3);
		}
		> .col-4 {
			width: (8.333333333333333% * 4);
		}
		> .col-1 {
			width: (8.333333333333333% * 1);
		}
		> div:last-child {
		}
		.button {
			height: 100%;
			display: block;
			width: calc(100% - 1.6rem);
			@include media-breakpoint-down(md) {
				width: calc(100% - 0.75rem);
			}
			span {
				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
			i {
				font-size: 180%;
				@include media-breakpoint-down(md) {
					font-size: 100%;
				}
			}
		}
	}
	.StripeElement {
		@include box-shadow(none);
		margin-top: 0;
		padding: 0;
		border: none;
	}
	.checkout-summary {
		margin-left: $spacer-lg;
		margin-right: $spacer-lg;
		margin-top: $spacer-sm;
	}
	.summary {
		table {
			// width: 100%;
			width: calc(100% - 3.2rem);
			margin: 0 1.6rem;
			margin-top: $spacer-sm;
			@include media-breakpoint-down(md) {
				width: calc(100% - 1.5rem);
				margin: 0 0.75rem;
			}
			th {
				text-align: right;
				padding-right: $spacer-sm;
				width: 70%;
				@include media-breakpoint-down(md) {
					width: 60%;
				}
			}
			td {
				width: 30%;
				text-align: right;
				@include media-breakpoint-down(md) {
					width: 40%;
				}
			}
		}
		span.price:not(.price-lg) {
			pre {
				@include font-size(1rem);
			}
		}
		span.price.price-lg {
			color: $black;
		}
	}
	> footer {
		padding: $spacer $spacer-lg $spacer-md;
		text-align: center;
		@include media-breakpoint-down(md) {
			padding: $spacer-sm $spacer;
		}
		.notice {
			margin-bottom: $spacer;
		}
		.button {
			max-width: 360px;
			margin: 0 auto;
			@include media-breakpoint-down(md) {
				max-width: 280px;
			}
			&.button-link {
				max-width: 100% !important;
				width: 100% !important;
				margin-left: 0 !important;
				margin-top: $spacer-sm;
			}
		}
		.dont {
			padding-top: $spacer-sm;
		}
		& + footer {
			padding-top: 0;
		}
		&.cols-2 {
			display: flex;
			flex-wrap: wrap;
			.button {
				width: 48%;
				&:first-child {
					margin-right: 2%;
				}
				&:last-child {
					margin-left: 2%;
				}
			}
		}
		> aside {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			width: 100%;
			margin-top: $spacer-md;
			> .button.button-link {
				width: auto !important;
				padding: 0 $spacer;
				margin: 0 !important;
				border-radius: 0 !important;
				&:not(:first-child) {
					border-left: $gray-400 2px solid;
				}
				&.l {
					text-align: right;
				}
				&.r {
					text-align: left;
				}
			}
		}
	}
	&.form-ui-terms {
		.logo {
			margin-top: 0;
		}
	}
	&.form-ui-verification {
		width: 90%;
		max-width: 560px;
		margin-left: auto;
		margin-right: auto;
		margin-top: $spacer-xxl * 2;
		.logo {
			// margin-top: 0;
		}
		> p {
			margin-top: 0;
			padding-top: 0;
		}
	}
}

.form-group {
	position: relative;
	// z-index: 1;
	margin-top: $spacer;
	padding-left: $spacer-lg;
	padding-right: $spacer-lg;
	@include media-breakpoint-down(md) {
		margin-top: $spacer;
		padding-left: $spacer-sm;
		padding-right: $spacer-sm;
	}
	@include media-breakpoint-down(sm) {
		margin-top: $spacer !important;
	}
	&:first-child {
		margin-top: 0;
		@include media-breakpoint-down(sm) {
			margin-top: 0 !important;
		}
		&[class*='col'] {
			& + [class*='col'] {
				margin-top: 0;
				&.colside-center {
					& + [class*='col'] {
						margin-top: 0;
					}
				}
			}
		}
	}
	label:not(.button) {
		color: $body-color;
		font-weight: $font-weight-bold;
		@include font-size($font-size-xs);
		text-transform: uppercase;
		text-align: left;
		display: block;
		position: relative;
		margin: 0;
		padding-left: $spacer-xs;
		line-height: 1;
		padding-top: $spacer-xs;
		@include text-truncate();
		@include font-size($font-size-xs);
		// @include media-breakpoint-down(md) {
		// 	@include font-size($font-size-xs);
		// 	line-height: $spacer-xl + $spacer-xxxs;
		// }
		// @include media-breakpoint-down(sm) {
		// 	text-align: left;
		// 	line-height: 1;
		// 	padding-top: $spacer-xs;
		// }
	}
	p.info {
		text-align: left;
		margin: 0;
		.mi {
			font-size: 30px;
			margin-left: $spacer;
			color: $gray-400;
		}
		.badge {
			@include border-radius($border-radius-md);
			line-height: $spacer-lg;
			padding: 0 $spacer-sm;
			margin-left: $spacer-xs;
		}
		> * {
			margin: 0 $spacer-xs;
			display: inline-block;
			vertical-align: middle;
		}
	}
	small {
		display: inline-block;
		background-color: $dark;
		color: $light;
		padding: 7px $spacer;
		text-align: center;
		@include border-radius($border-radius-sm);
		position: absolute;
		bottom: calc(100% - 10px);
		left: 50%;
		width: 180px;
		margin-left: -90px;
		margin-top: -$spacer-xs;
		animation: float 1.2s ease-in-out infinite;
		z-index: 999;
		&:before {
			content: ' ';
			display: block;
			width: 10px;
			height: 10px;
			background-color: $dark;
			position: absolute;
			top: 100%;
			margin-top: -5px;
			margin-left: -3px;
			left: 50%;
			transform: rotate(45deg);
		}
		span {
			display: block;
			line-height: 1;
		}
	}
	textarea,
	input[type='text'],
	input[type='email'],
	input[type='number'],
	input[type='password'],
	.react-select__single-value {
		@include text-control-mixin();
	}
	input[type='number'] {
		text-align: left;
	}
	textarea {
		min-height: $spacer-md * 3;
		line-height: $spacer-md;
		padding: 0 $spacer;
	}
	div.select {
		select {
			border: none;
			background: transparent;
			width: 100%;
			padding: 0;
			margin-left: -($spacer-xxs);
			margin-right: -($spacer-xxs);
			color: $headings-color;
			font-weight: $font-weight-bold;
			@include media-breakpoint-down(md) {
				@include font-size($font-size-sm);
			}
			&:focus,
			&:active {
				outline-style: none;
			}
			&:disabled {
				opacity: 0.3;
				cursor: not-allowed;
			}
		}
	}
	.react-datepicker {
		display: flex;
	}
	.react-datepicker-wrapper {
		display: block;
		.react-datepicker__input-container {
			display: block;
		}
	}
	> .form-row {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		@include border-top-radius(0);
		border: $border-color 2px solid;
		@include border-top-radius($border-radius-sm);
		padding: 0;
		position: relative;
		width: 100%;
		&.has-append {
			.append {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				width: $spacer-xxxl;
				height: 100%;
				border-left: $gray-200 1px solid;
				i {
					width: $spacer-xxxl;
					line-height: $spacer-xxxl;
					font-weight: bold;
					text-align: center;
					display: block;
					@include font-size($font-size-base);
				}
				i.mi {
					font-size: 20px;
					@include media-breakpoint-down(sm) {
						font-size: 16px;
					}
				}
			}
			> div {
				&:last-child {
					padding-right: $spacer-xxxl;
				}
			}
		}
		&.has-prepend {
			.prepend {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: $spacer-xxxl;
				height: 100%;
				border-right: $gray-200 1px solid;
				i {
					width: $spacer-xxxl;
					line-height: $spacer-xxxl;
					font-weight: bold;
					text-align: center;
					display: block;
					@include font-size($font-size-base);
					@include media-breakpoint-down(sm) {
						line-height: 2;
					}
				}
				i.mi {
					font-size: 20px;
				}
			}
			> div {
				&:last-child {
					padding-left: $spacer-xxxl;
					> * {
						padding-left: $spacer-xs;
					}
				}
			}
		}
		> div {
			padding: 0;
			width: 100% !important;
			@include media-breakpoint-down(sm) {
			}
			&:first-child {
				padding-right: ($form-gutter-width / 2);
			}
			&:last-child {
				padding-left: ($form-gutter-width / 2);
			}
			// &.col-2 {
			// 	width: 16.6632%;
			// 	@include media-breakpoint-down(lg) {
			// 		width: 33.3264%;
			// 	}
			// }
			// &.col-3 {
			// 	width: 25%;
			// 	@include media-breakpoint-down(md) {
			// 		width: 33.3264%;
			// 	}
			// }
			// &.col-6 {
			// 	width: 50%;
			// }
			// &.col-5 {
			// 	width: 8.333333% * 5;
			// }
			// &.col-7 {
			// 	width: 8.333333% * 7;
			// }
			// &.col-12 {
			// 	width: 100%;
			// }
			// &.col-4 {
			// 	width: 33.3264%;
			// }
			// &.col-8 {
			// 	width: 66.6528%;
			// }
			// &.col-9 {
			// 	width: 75%;
			// 	@include media-breakpoint-down(md) {
			// 		width: 66.6528%;
			// 	}
			// }
			// &.col-10 {
			// 	width: 83.316%;
			// 	@include media-breakpoint-down(lg) {
			// 		width: 66.6528%;
			// 	}
			// }
			&:not([class*='col']) {
				width: 100%;
				label:not(.button) {
					text-align: left;
					line-height: 1.5;
					padding-left: $spacer-lg;
					padding-top: $spacer;
					padding-bottom: $spacer-sm;
				}
			}
		}
		& + .form-row {
			@include border-radius(0);
		}
		&:last-child {
			@include border-bottom-radius($border-radius-sm);
		}
	}
	.form-subgroup {
		display: flex;
	}
	> .mi {
		position: absolute;
		top: 50%;
		right: $spacer-sm;
		line-height: $spacer-lg;
		margin-top: -($spacer-lg / 1.7);
		background-color: $white;
	}
	.react-select {
		.react-select__control {
			border: none;
		}
	}
	&.active {
		> div {
			border-color: $primary !important;
			background-color: lighten($primary, 40%);
			@include gradient-bg(lighten($primary, 40%));
		}
		> .form-row.has-append .append i {
			color: $primary;
		}
	}
	&:hover {
		> div {
			border-color: $gray-400;
		}
	}
	&.is-invalid {
		> div {
			border-color: $danger;
		}
		> .mi.error {
			visibility: visible;
		}
		small {
			background-color: $danger;
			&:before {
				background-color: $danger;
			}
		}
	}
	&.has-popover {
		> .form-row {
			> div {
				&:last-child {
					padding-right: 3rem;
					position: relative;
					.popover {
						position: absolute;
						top: 50%;
						right: $spacer-xs;
						width: 2rem;
						height: 2rem;
						margin-top: -1rem;
						text-align: center;
						cursor: default;
						> i {
							line-height: 2rem;
							font-size: 24px;
							color: $gray-400;
						}
					}
				}
			}
		}
	}
	&.form-group-radio {
		> .form-row {
			border: none;
			> div {
				@include media-breakpoint-down(sm) {
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
			}
		}
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			margin-left: -($spacer-sm);
			margin-right: -($spacer-sm);
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			@include media-breakpoint-down(sm) {
				display: block;
				margin-left: 0;
				margin-right: 0;
			}
			li {
				padding: 0 $spacer-sm;
				@include media-breakpoint-down(sm) {
					padding: 0;
				}
				> * {
					display: inline-block;
					vertical-align: middle;
				}
				> input {
					display: none;
					&:checked {
						& + label {
							> b {
								border-color: $primary;
								&:before {
									background-color: $primary;
									background: $primary
										linear-gradient(
											180deg,
											mix($body-bg, $primary, 25%),
											darken($primary, 8%)
										)
										repeat-x !important;
								}
							}
						}
					}
				}
				> label {
					@include media-breakpoint-down(sm) {
						padding-left: 0;
						padding-top: 0;
						padding-bottom: $spacer-xs;
					}
					> * {
						display: inline-block;
						vertical-align: middle;
						// line-height: $spacer-lg;
					}
					cursor: pointer;
					> b {
						width: 27px;
						height: 27px;
						line-height: 27px;
						background-color: $gray-100;
						border: $border-width $gray-400 solid;
						@include border-radius(50%);
						position: relative;
						padding: 0;
						text-align: center;
						margin-right: 4px;
						box-shadow: $box-shadow-sm inset;
						&:before {
							width: 17px;
							height: 17px;
							@include border-radius(50%);
							content: ' ';
							display: block;
							margin: 3px auto;
						}
					}
					&:hover {
						> b {
							&:before {
								background-color: $gray-500;
								background: $gray-500
									linear-gradient(
										180deg,
										mix($body-bg, $gray-500, 25%),
										darken($gray-500, 8%)
									)
									repeat-x !important;
							}
						}
					}
				}
			}
		}
	}
	&.form-group-list-select {
		h4 {
			text-align: center;
			color: $gray-900;
		}
		ul {
			list-style: none;
			margin: 0;
			margin-left: -($spacer-sm);
			margin-right: -($spacer-sm);
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			> li {
				margin-top: $spacer-sm;
				width: 50%;
				padding: 0 $spacer-sm;
				@include media-breakpoint-down(xs) {
					width: 100%;
				}
				.button {
					width: 100%;
					display: block;
					text-align: left;
				}
			}
		}
	}
	&[class*='cols'] {
		&.colside-left {
			padding-right: ($form-gutter-width / 2);
		}
		&.colside-right {
			padding-left: ($form-gutter-width / 2);
		}
		@include media-breakpoint-down(sm) {
			padding-right: $spacer-sm !important;
			padding-left: $spacer-sm !important;
			width: 100% !important;
		}
	}
	&.cols-6 {
		width: 50%;
	}
	&.cols-7 {
		width: 8.333333% * 7;
	}
	&.cols-5 {
		width: 8.333333% * 5;
	}
	&.cols-8 {
		width: 8.333333% * 8;
	}
	&.cols-4 {
		width: 8.333333% * 4;
	}
	&.is-disabled {
		cursor: not-allowed !important;
		label:not(.button) {
			color: $gray-500;
		}
		> div {
			background-color: $gray-200 !important;
			border-color: $gray-200 !important;
		}
	}
	&.form-group-stripe {
		.form-row {
			padding: $spacer-md 0 $spacer;
		}
	}
	@for $i from 1 through 11 {
		&.upper-#{$i} {
			z-index: 11 + $i;
		}
	}
}

.form-inner-box {
	background-color: darken($gray-100, 0%);
	border: $gray-200 1px solid;
	padding: $spacer-md 0;
	margin-top: $spacer;
	margin-left: $spacer-lg;
	margin-right: $spacer-lg;
	@include border-radius($border-radius-sm);
	@include box-shadow($box-shadow-sm);
	@include media-breakpoint-down(md) {
		margin-top: $spacer;
		margin-left: $spacer-sm;
		margin-right: $spacer-sm;
	}
	.indent {
		padding-left: $spacer-xxl;
	}
	.form-group {
		> .form-row {
			background-color: $white;
		}
	}
	> * {
		&:not(:first-child) {
			margin-top: $spacer;
		}
	}
}

.form-group.form-group-check {
	> .form-row {
		border: none;
		> div {
			display: flex;
			justify-content: center;
			input {
				display: none;
				&:checked {
					& + label {
						> b {
							border-color: $primary;
							background-color: $primary;
							&:before {
								border-color: $white;
							}
						}
					}
				}
				&:disabled {
					& + label {
						> b {
							background-color: $gray-300;
							border-color: transparent;
							&:before {
								border-color: transparent;
							}
						}
					}
					&:checked {
						& + label {
							> b {
								&:before {
									border-color: $gray-500;
								}
							}
						}
					}
				}
			}
			label:not(.button) {
				cursor: pointer;
				text-align: left;
				white-space: normal;
				line-height: 1.5;
				padding-left: 0;
				@include media-breakpoint-down(sm) {
					padding-top: 0;
				}
				> * {
					display: inline-block;
					vertical-align: middle;
					// line-height: $spacer-lg;
					@include media-breakpoint-down(sm) {
						width: calc(100% - 45px);
					}
				}
				> span {
				}
				> em {
					display: block;
					text-transform: none;
					line-height: 1.5;
					padding-left: 41px;
					font-weight: $font-weight-normal;
					margin-top: -$spacer-xxxs;
					font-size: $font-size-sm;
					margin-bottom: 0;
					@include media-breakpoint-down(md) {
						margin-top: $spacer-xxs;
						margin-bottom: 0;
					}
				}
				> b {
					width: 27px;
					height: 27px;
					line-height: 27px;
					background-color: $gray-100;
					border: $border-width $gray-400 solid;
					@include border-radius($border-radius-sm);
					position: relative;
					padding: 0;
					text-align: center;
					margin-right: 11px;
					box-shadow: $box-shadow-sm inset;
					&:before {
						width: 15px;
						height: 10px;
						// @include border-radius(50%);
						content: ' ';
						display: block;
						margin: 5px auto;
						border: 4px transparent solid;
						border-top: none;
						border-right: none;
						transform: rotate(-45deg);
					}
				}
				&:hover {
					> b {
						&:before {
							border-color: $gray-500;
						}
						// &:before {
						// 	background-color: $gray-500;
						// 	background: $gray-500
						// 		linear-gradient(
						// 			180deg,
						// 			mix($body-bg, $gray-500, 25%),
						// 			darken($gray-500, 8%)
						// 		)
						// 		repeat-x !important;
						// }
					}
				}
			}
		}
	}
	&.multiple {
		max-width: 480px;
		margin-left: auto;
		margin-right: auto;
		> .form-row {
			display: block;
			// flex-wrap: wrap;
			// justify-content: left;
			> div {
				padding-right: 0;
				padding-left: 0;
				// width: 33.3264%;
				justify-content: left;
			}
		}
		label:not(button) {
			text-overflow: unset;
			padding-left: 0;
			> span {
			}
		}
	}
}

.modal-form {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999999;
	width: 100%;
	height: 100%;
	background-color: rgba($gray-600, 0);
	@include transition(background-color 0.2s ease-out);
	overflow: auto;
	> div {
		margin: $spacer-xl auto;
		width: 96%;
		max-width: 480px;
		position: relative;
		opacity: 0;
		// left: -($spacer-xxl);
		@include transition(all 0.2s ease-out);
		// transform: scale(1.2);
		@include media-breakpoint-down(sm) {
			margin: $spacer-sm auto;
		}

		> .close,
		> .back {
			background: transparent;
			border: none;
			width: $spacer-xl;
			height: $spacer-xl;
			padding: 0;
			position: absolute;
			top: $spacer-md;
			@include media-breakpoint-down(sm) {
				top: $spacer-sm;
			}
			.mi {
				height: $spacer-xl;
				line-height: $spacer-xl;
				color: $body-color;
			}
		}
		> .close {
			right: $spacer-sm;
		}
		> .back {
			left: $spacer-sm;
		}
		> .more-holder {
			position: absolute;
			top: $spacer-md;
			right: $spacer-sm + $spacer-xl;
			@include media-breakpoint-down(sm) {
				top: $spacer-sm;
			}
			div.more {
				> button {
					color: $gray-600;
				}
			}
		}
	}
	&.modal-form-lg {
		> div {
			max-width: 900px;
		}
	}
	&.modal-form-md {
		> div {
			max-width: 720px;
			@include media-breakpoint-down(md) {
				max-width: 620px;
			}
		}
	}
	.form-ui {
		@include box-shadow($box-shadow-darker);
		h1 {
			padding-left: $spacer-xl + $spacer-sm;
			padding-right: $spacer-xl + $spacer-sm;
			margin-bottom: $spacer;
			padding-top: $spacer-md;
			& + small.badge {
				margin-top: 0;
			}
			@include media-breakpoint-down(md) {
				margin-bottom: $spacer-xs;
				padding-top: $spacer;
			}
		}
		> span.price {
			margin: 0 auto;
			text-align: center;
			margin-bottom: $spacer-sm;
			& + footer {
				margin-top: -$spacer-sm;
				@include media-breakpoint-down(md) {
					margin-top: -$spacer-xxs;
				}
			}
			@include media-breakpoint-down(md) {
				margin-bottom: $spacer-xxs;
			}
		}
		> span.img {
			width: 8rem;
			height: 8rem;
			margin: $spacer-lg auto;
			margin-bottom: 0;
			@include media-breakpoint-down(md) {
				width: 7.2rem;
				height: 7.2rem;
				margin: $spacer-lg auto;
				margin-bottom: 0;
			}
		}
		> footer:not(.cols-2) {
			.button {
				width: 66%;
				max-width: 320px;
				@include media-breakpoint-down(md) {
					max-width: 240px;
				}
				&.block {
					width: 100% !important;
					max-width: 100% !important;
				}
			}
		}
		> p {
			padding: 0 $spacer-lg;
			&.lead {
				text-align: center;
				@include font-size($font-size-lg);
			}
		}
		> small {
			display: block;
			text-align: center;
			margin-top: $spacer-sm;
			& + small {
				margin-top: 0;
			}
		}
	}
	.adds {
		padding: 0 $spacer-lg;
	}
	&.show {
		background-color: rgba($gray-600, 0.7);
		> div {
			// left: 0;
			opacity: 1;
			// transform: scale(1);
		}
		&.close {
			background-color: rgba($gray-600, 0);
			> div {
				// left: ($spacer-xxl);
				// transform: scale(0.9);
				opacity: 0;
				@include transition(all 0.2s ease-in);
			}
		}
	}
}

.checkout-summary {
	> div {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		label,
		b {
			display: block;
			margin: 0;
			padding-right: $spacer;
			@include font-size($font-size-xl);
			min-width: 12rem;
		}
		span.price {
			@include font-size($font-size-xl);
			color: $green;
			min-width: 12rem;
			text-align: right;
			pre {
				@include font-size($font-size-xl);
			}
		}
	}
}

.qr-reader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	> div {
		width: 414px;
		@include media-breakpoint-down(sm) {
			width: 100%;
			max-width: 414px;
		}
		> div.loading {
			.spinner {
				position: absolute;
				margin-top: -3rem;
			}
		}
		> section {
			@include border-radius($border-radius-sm);
			overflow: hidden;
			@include media-breakpoint-down(sm) {
				width: 100% !important;
				height: auto !important;
				@include border-radius(0);
			}
			> section {
				> div {
					@include border-radius($border-radius-sm);
					box-shadow: rgba($primary, 1) 0px 0px 0px 5px inset !important;
					border: 50px solid rgba($black, 0.5) !important;
					@include media-breakpoint-down(sm) {
						border: 20px solid rgba($black, 0.5) !important;
					}
				}
			}
		}
		.button {
			margin-top: $spacer-sm;
			@include media-breakpoint-down(sm) {
				margin-top: 0;
			}
		}
	}
}

.icon-counter {
	background-color: $info;
	color: $white;
	display: block;
	padding-top: 100%;
	position: relative;
	@include border-radius($border-radius-sm);
	i {
		font-size: 50px;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50px;
		height: 50px;
		line-height: 50px;
		margin-top: -34px;
		margin-left: -25px;
	}
	&.success {
		background-color: $success;
	}
	&.danger {
		background-color: $danger;
	}
	&.warning {
		background-color: $warning;
	}
}

.input-check {
	width: $input-check-size * 2;
	input {
		height: 0;
		width: 0;
		visibility: hidden;
	}
	label,
	.label {
		cursor: pointer;
		text-indent: -9999px;
		width: $input-check-size * 2;
		height: $input-check-size;
		background: $gray-500;
		display: block;
		border-radius: 100px;
		position: relative;
		color: transparent !important;
	}

	label:after,
	.label:after {
		content: '';
		position: absolute;
		top: $input-check-padding;
		left: $input-check-padding;
		width: ($input-check-size - ($input-check-padding * 2));
		height: ($input-check-size - ($input-check-padding * 2));
		background: #fff;
		border-radius: ($input-check-size - ($input-check-padding * 2));
		transition: 0.3s;
	}

	input:checked + label,
	input:checked + .label {
		background: $primary;
	}

	input:checked + label:after,
	input:checked + .label:after {
		left: calc(100% - 2px);
		transform: translateX(-100%);
	}

	label:active:after,
	.label:active:after {
		// width: $input-check-size + ($input-check-padding * 6);
	}
}

input.text-control {
	@include text-control-mixin();
	background-color: $white;
	border: $border-color 2px solid;
	@include border-radius($border-radius-sm);
	padding: 0 $spacer-sm;
	@include media-breakpoint-down(md) {
		padding: 0 $spacer-xs;
	}
	&:focus,
	&:active {
		outline-style: none;
		border-color: $primary !important;
		background-color: lighten($primary, 40%);
		@include gradient-bg(lighten($primary, 40%));
	}
}
