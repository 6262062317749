.topbar {
	display: flex;
	justify-content: space-between;
	padding: 0 $spacer;
	align-items: center;
	@include box-shadow($box-shadow-sm);
	background-color: rgba($white, 0.87);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	// @include transition(0.3s padding ease-out);
	backdrop-filter: blur(4px);
	z-index: 9997;
	height: $topbar-h;
	@include media-breakpoint-down(md) {
		height: $topbar-h-md;
	}
	@include media-breakpoint-down(sm) {
		padding: 0 $spacer-sm;
		display: flex;
		flex-wrap: wrap;
		height: auto;
		padding: 0;
		position: relative;
		margin-bottom: $spacer;
	}
	> * {
		@include media-breakpoint-down(sm) {
			display: block;
			width: 100%;
			order: 1;
		}
	}
	> .tabs {
		@include media-breakpoint-down(sm) {
			order: 2;
		}
		@include media-breakpoint-down(xs) {
			// padding-top: $spacer-xxs;
		}
	}
	&.no-navbar {
		padding-left: $spacer-lg;
		@include media-breakpoint-down(sm) {
			padding-left: $spacer-sm;
		}
	}
	.pull-left,
	.pull-right {
		// display: flex;
	}
	// .pull-right {
	// 	> * {
	// 		margin-left: $spacer;
	// 		@include media-breakpoint-down(sm) {
	// 			margin-left: 0;
	// 		}
	// 	}
	// }
	// .pull-left {
	// 	> * {
	// 		margin-right: $spacer;
	// 	}
	// }
	h2 {
		padding: $spacer-lg 0 $spacer;
		line-height: 1;
		color: $headings-color;
		margin-right: $spacer;
	}
}

.dashboard {
	> .main {
		// padding-top: ($spacer-xl * 2) + $spacer-xxs;
		padding-top: $topbar-h;
		position: relative;
		height: 100%;
		> div:not([class]) {
			height: 100%;
			@include media-breakpoint-down(md) {
				height: auto;
			}
		}
		@include media-breakpoint-down(md) {
			padding-top: ($spacer-lg * 2);
		}
		@include media-breakpoint-down(sm) {
			padding-top: 0;
		}
	}
}

ul.tabs {
	list-style: none;
	display: flex;
	padding: 0;
	margin: 0;
	margin-bottom: -1px;
	padding-left: $spacer;
	@include media-breakpoint-down(sm) {
		padding-left: 0;
		justify-content: center;
	}
	&.limit-w {
		> li {
			@include media-breakpoint-down(xs) {
				max-width: 50px;
			}
			> a {
				@include media-breakpoint-down(xs) {
					@include text-truncate();
				}
			}
		}
	}
	> li {
		border-bottom: transparent $spacer-xxs solid;
		position: relative;
		> a {
			padding: 0 $spacer 0;
			display: block;
			color: $body-color;
			line-height: $topbar-h - $spacer-xxs;
			font-weight: $font-weight-bold;
			@include font-size($font-size-base);
			@include media-breakpoint-down(md) {
				line-height: $topbar-h-md - $spacer-xxs;
				@include font-size($font-size-base * 0.9);
			}
			@include media-breakpoint-down(sm) {
				padding: 0 $spacer-xs 0;
				@include font-size($font-size-base * 0.8);
			}
			@include media-breakpoint-down(xs) {
				line-height: $topbar-h-md - $spacer;
				// padding: 0 $spacer-xxs 0;
				// @include font-size($font-size-base * 0.7);
			}
			&:hover {
				text-decoration: none;
			}
		}

		pre {
			background-color: $red;
			color: $white;
			line-height: 22px;
			margin: 0;
			margin-top: -(18px);
			padding: 0 $spacer-xs;
			position: absolute;
			top: 50%;
			right: -$spacer-sm;
			border-radius: 0.8rem;
			font-family: $font-family-base;
			font-weight: $font-weight-bold;
			font-size: 11px;
			@include media-breakpoint-down(sm) {
				font-size: 8px;
				line-height: 16px;
				margin-top: -12px;
				right: -10px;
				padding: 0 $spacer-xxs;
			}
		}
		&.active {
			border-bottom-color: $primary;
			> a {
				color: $primary;
			}
		}
		&.title {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 25px;

			p {
				font-weight: bold;
				font-size: 1.2em;
				margin: 0;
				color: black;
			}
			@include media-breakpoint-down(sm) {
				margin-right: 20px;
				margin-left: 40px;
			}
		}
		&.blue {
			> a {
				color: $blue;
			}
		}
		&.center {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&.alert {
			margin-left: 10px;
			padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			div {
				border-radius: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 5px 20px;
				background-color: $blue;
				p {
					margin: 0;
					text-align: center;
					color: white;
					font-weight: bold;
					font-size: 0.8em;
				}
			}
		}
	}
}

// aside.filters {
// 	display: flex;
// 	padding: 0;
// 	height: $topbar-h;
// 	@include media-breakpoint-down(md) {
// 		height: $topbar-h-md;
// 	}
// 	> * {
// 		margin-left: $spacer-sm;
// 		&:first-child {
// 			margin-left: 0;
// 		}
// 	}
// 	select {
// 		background: transparent;
// 		border: none;
// 		color: $body-color;
// 		font-weight: $font-weight-bold;
// 		border-bottom: $gray-300 2px solid;
// 		background-color: $gray-50;
// 		@include gradient-bg($gray-50);
// 		@include border-radius($border-radius-sm);
// 		padding: 0 $spacer;
// 		@include font-size($font-size-base);
// 		margin-top: $spacer-xs;
// 		// @include box-shadow(inset $box-shadow-input);
// 		// height: 2.8rem;
// 		line-height: $topbar-object-h;
// 		height: $topbar-object-h;
// 		@include media-breakpoint-down(md) {
// 		}
// 		&:focus,
// 		&:active {
// 			outline-style: none;
// 		}
// 		&:disabled {
// 			opacity: 0.3;
// 			cursor: not-allowed;
// 		}
// 	}
// }

div.actions {
	padding: 0;
	height: $topbar-h;
	@include media-breakpoint-down(md) {
		height: $topbar-h-md;
	}
	@include media-breakpoint-down(sm) {
		text-align: center;
		height: auto;
		padding-bottom: $spacer-xs;
	}
	@include media-breakpoint-down(xs) {
		// padding-bottom: 3px;
	}
	&:after {
		content: ' ';
		display: block;
		clear: both;
	}
	> * {
		vertical-align: bottom;
	}
	> div:not([class]) {
		display: inline-block;
		float: none;
		div.more {
			padding-left: $spacer-sm;
			line-height: $topbar-object-h;
			height: $topbar-object-h;
			@include media-breakpoint-down(md) {
				line-height: $topbar-object-h-md;
				height: $topbar-object-h-md;
				padding-left: $spacer-xs;
			}
			@include media-breakpoint-down(sm) {
				padding-left: 0;
			}
			> button {
				line-height: $topbar-object-h;
				height: $topbar-object-h;
				@include media-breakpoint-down(md) {
					line-height: $topbar-object-h-md;
					height: $topbar-object-h-md;
				}
				i {
					line-height: $topbar-object-h;
					height: $topbar-object-h;
					@include media-breakpoint-down(md) {
						line-height: $topbar-object-h-md;
						height: $topbar-object-h-md;
					}
				}
			}
			.dropdown {
				width: 260px;
				.button {
					margin-left: 0 !important;
					> * {
						line-height: 1;
					}
				}
			}
		}
	}
	div.search {
		display: inline-block;
		float: none;
		position: relative;
		margin-top: 0;
		input {
			border: none;
			padding: 0;
			display: block;
			width: 110px;
			line-height: $topbar-object-h;
			height: $topbar-object-h;
			border-bottom: $gray-300 2px solid;
			padding-right: $spacer-lg;
			@include media-breakpoint-down(xs) {
			}
			@include media-breakpoint-down(xs) {
				font-size: $font-size-sm;
				width: 80px;
			}
			&:focus,
			&:active {
				border-bottom-color: $primary;
				outline-style: none;
				width: 150px;
				& + i {
					// color: $gray-100;
				}
			}
		}
		i {
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -$spacer-sm;
			font-size: 24px;
			color: $gray-400;
		}
	}
	.button,
	.date-wrapper {
		float: none;
		display: inline-block;
		vertical-align: middle;
		margin-top: $spacer-xs;
		width: auto;
		padding: 0 $spacer-md 0;
		padding-left: $spacer;
		border-width: $spacer-xxxs;
		@include media-breakpoint-down(md) {
			padding: 0 $spacer-sm 0;
			padding-left: $spacer-xxs;
		}
		@include media-breakpoint-down(sm) {
			padding-left: $spacer-xs;
			padding-right: $spacer-sm;
		}
		&:not(:first-child) {
			margin-left: $spacer-sm;
			@include media-breakpoint-down(sm) {
				margin-left: $spacer-xs / 2;
			}
		}
		> * {
			display: inline-block;
			vertical-align: middle;
			line-height: $topbar-object-h;
			@include media-breakpoint-down(md) {
				line-height: $topbar-object-h-md;
			}
		}
	}
	.button {
		@include media-breakpoint-down(xs) {
			padding: 0 7px 0 2px;
		}
		> span {
			@include font-size($font-size-md);
			@include media-breakpoint-down(md) {
				@include font-size($font-size-sm);
			}
			@include media-breakpoint-down(sm) {
				// display: none;
				@include font-size($font-size-xs * 0.9);
			}
		}
		> i {
			margin-right: 0;
		}
		@include media-breakpoint-down(sm) {
			border-radius: $spacer-xxs;
			margin-left: $spacer-xxs;
			margin-right: $spacer-xxs;
		}
		@include media-breakpoint-down(xs) {
			margin-left: 2px;
			margin-right: 2px;
		}
		&:not(:first-child) {
			@include media-breakpoint-down(sm) {
				margin-left: 0;
			}
		}
		&:first-child {
			@include media-breakpoint-down(sm) {
				// @include border-left-radius($spacer-xs);
			}
		}
		&:last-child {
			@include media-breakpoint-down(sm) {
				// @include border-right-radius($spacer-xs);
			}
		}
		&.button-responsive-text {
			// > span {
			// 	display: block !important;
			// 	@include media-breakpoint-down(sm) {
			// 		font-size: 11px;
			// 	}
			// }
		}
	}
	.date-wrapper {
		margin-top: 0;
	}
}

.tag {
	margin: 0;
	margin-top: $spacer-xs;
	display: inline-block;
	vertical-align: middle;
	background-color: $gray-300;
	line-height: $spacer-xl;
	height: $spacer-xl;
	position: relative;
	padding: 0 $spacer 0 $spacer-xs;
	margin-right: $spacer;
	@include border-left-radius($border-radius-sm);
	&:after {
		content: ' ';
		display: block;
		width: 0;
		height: 0;
		border-top: ($spacer-xl / 2) solid transparent;
		border-left: ($spacer-xl / 1.7) solid $gray-300;
		border-bottom: ($spacer-xl / 2) solid transparent;
		position: absolute;
		left: 100%;
		top: 0;
	}
	span {
		display: inline-block;
		height: $spacer-xl;
		line-height: $spacer-xl;
		vertical-align: top;
		font-weight: $font-weight-bold;
		color: $body-color;
	}
	button {
		vertical-align: top;
		display: inline-block;
		padding: 0;
		margin: 0;
		border: none;
		width: $spacer-xl;
		height: $spacer-xl;
		line-height: $spacer-xl;
		background: transparent;
		outline-style: none;
		text-align: center;
		margin-right: $spacer-xs;
		i {
			line-height: $spacer-xl;
			color: $body-color;
		}
		&:hover,
		&:active,
		&:focus {
			i {
				color: $black;
			}
		}
	}
	@each $color, $value in $colors {
		&.#{$color} {
			background-color: $value;
			&:after {
				border-left-color: $value;
			}
			span {
				color: color-yiq($value);
			}
			button {
				i {
					color: color-yiq($value);
				}
				&:hover,
				&:active,
				&:focus {
					i {
						opacity: 0.7;
					}
				}
			}
		}
	}
}

p.notice {
	background-color: $primary;
	text-align: center;
	padding: $spacer-xs $spacer-xl;
	color: $white;
	@include border-radius($border-radius-xs);
	margin: 0;
	margin-bottom: $spacer-xs;
	span.price {
		color: $white;
		display: inline-block;
	}
	> * {
		margin: 0 $spacer-xs;
		display: inline-block;
		vertical-align: middle;
	}
	&.danger {
		background-color: $danger;
	}
}

.button.button-cart {
	position: relative;
	// margin-right: $spacer-sm;
	padding-right: $spacer-xxl * 1.35 !important;
	overflow: hidden;
	@include media-breakpoint-down(md) {
		padding-right: $spacer-xxl * 1 !important;
	}
	@include media-breakpoint-down(sm) {
		padding-right: $spacer-xxl * 0.86 !important;
	}
	> * {
		display: inline-block;
		vertical-align: middle;
	}
	> i {
		// color: $white;
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	> span.price {
		// color: $white;
		> * {
			display: inline-block;
			vertical-align: middle;
		}
		pre {
			margin: 0;
			@include font-size(1.1rem);
			@include media-breakpoint-down(md) {
				@include font-size(1rem);
			}
			@include media-breakpoint-down(sm) {
				@include font-size(0.86rem);
			}
		}
		> i {
			font-size: 20px;
			@include media-breakpoint-down(md) {
				font-size: 18px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 16px;
			}
		}
	}
	> b {
		background-color: darken($secondary, 10%);
		// color: $white;
		height: $topbar-object-h;
		line-height: $topbar-object-h;
		padding: 0 $spacer-xs;
		display: block;
		position: absolute;
		top: 0;
		right: -2px;
		padding-right: $spacer;
		padding-left: $spacer-sm;
		@include font-size($font-size-md);
		// @include box-shadow($box-shadow-sm);
		// @include border-right-radius(3rem);
		@include media-breakpoint-down(md) {
			height: $topbar-object-h-md;
			line-height: $topbar-object-h-md;
		}
		@include media-breakpoint-down(sm) {
			padding-right: $spacer-sm;
			padding-left: $spacer-xs;
		}
	}
	&.pop-it-cart {
		transform: scale(1.2);
		box-shadow: 0 0 15px $success;
		background-color: lighten($success, 10%);
		border-color: lighten($success, 20%);
	}
}
