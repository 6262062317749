$messages-head-foot-h: 4.5rem;
$chat-bubble-h: 6rem;
#root {
	> #main {
		> .dashboard {
			&.chat {
				> .main {
					padding-top: 0;
				}
			}
		}
	}
}

iframe[title~='chat'] {
	display: none !important;
	transform: translateY(-9999rem);
}

.chat-ui {
	display: flex;
	height: 100%;
	ul.people {
		padding: 0;
		margin: 0;
		width: 30%;
		background-color: $gray-300;
		height: 100%;
		overflow: auto;
		@include media-breakpoint-down(sm) {
			width: 35%;
		}
		> li {
			border-bottom: $gray-400 1px solid;
		}
	}
	div.messages {
		width: 70%;
		// padding-right: 100px;
		height: 100%;
		@include box-shadow($box-shadow-lg);
		@include media-breakpoint-down(sm) {
			width: 65%;
		}
		> div {
			width: 100%;
			height: 100%;
			background-color: $gray-500;
			position: relative;
			padding-bottom: $chat-bubble-h;
			@include media-breakpoint-down(sm) {
				padding-bottom: 0;
			}
			aside.empty {
				> i {
					color: $gray-200;
				}
			}
			> header {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: $messages-head-foot-h;
				background-color: $gray-100;
				@include box-shadow($box-shadow-lg);
				display: flex;
				flex-wrap: wrap;
				z-index: 3;
				h1 {
					line-height: $messages-head-foot-h;
					color: $gray-900;
					width: 70%;
					padding: 0 $spacer-md;
					@include text-truncate();
					@include font-size($font-size-base);
					@include media-breakpoint-down(sm) {
						width: 80%;
						@include font-size($font-size-sm);
					}
				}
				button {
					width: 30%;
					border-radius: 0;
					padding: 0;
					border: none;
					@include media-breakpoint-down(sm) {
						width: 20%;
					}
					span {
						@include media-breakpoint-down(sm) {
							display: none;
						}
					}
				}
			}
			> aside {
				height: 100%;
				padding: $messages-head-foot-h $spacer-lg;
				overflow: auto;
				background-color: $gray-200;
				@include media-breakpoint-down(sm) {
					padding: $messages-head-foot-h $spacer-sm;
				}
				> div {
					margin-top: $spacer;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-end;
					@include media-breakpoint-down(sm) {
						margin-top: $spacer-sm;
					}
					&:last-child {
						margin-bottom: $spacer;
						@include media-breakpoint-down(sm) {
							margin-bottom: $spacer-sm;
						}
					}
					p {
						width: 80%;
						max-width: 420px;
						@include box-shadow($box-shadow-sm);
						margin: 0;
						border-radius: 0.7rem;
						padding: $spacer-sm $spacer;
						background-color: $primary;
						color: $white;
						@include media-breakpoint-down(sm) {
							width: 90%;
							@include font-size($font-size-sm);
							padding: $spacer-xs $spacer-sm;
						}
						span {
							display: block;
							line-height: 1.2;
						}
						em {
							@include font-size($font-size-sm);
							opacity: 0.6;
							display: block;
							line-height: 1;
							margin-top: $spacer-xs;
							@include media-breakpoint-down(sm) {
								@include font-size($font-size-sm * 0.8);
							}
						}
					}
					&.left {
						justify-content: flex-start;
						p {
							background-color: $white;
							color: $gray-900;
						}
					}
					&.fake {
						opacity: 0.6;
					}
				}
			}
			> footer {
				height: $messages-head-foot-h;
				width: 100%;
				background-color: $gray-100;
				@include box-shadow($box-shadow-lg);
				display: flex;
				position: absolute;
				bottom: $chat-bubble-h;
				left: 0;
				display: flex;
				flex-wrap: wrap;
				z-index: 3;
				@include media-breakpoint-down(sm) {
					bottom: 0;
				}
				input {
					width: 70%;
					padding: 0 $spacer-sm;
					border-radius: 0;
					@include media-breakpoint-down(sm) {
						width: 80%;
					}
				}
				.button {
					width: 30%;
					border-radius: 0;
					border-radius: 0;
					padding: 0;
					border: none;
					@include media-breakpoint-down(sm) {
						width: 20%;
					}
					span {
						@include media-breakpoint-down(sm) {
							display: none;
						}
					}
				}
			}
		}
	}
}

.card.vcard.chat-card {
	cursor: pointer;
	border-radius: 0;
	@include media-breakpoint-down(sm) {
		padding: $spacer-xs 0;
	}
	> .avatar {
		@include media-breakpoint-down(sm) {
			width: 2rem;
			margin-top: 0;
		}
		span.img {
			@include media-breakpoint-down(sm) {
				height: 2rem;
				width: 2rem;
			}
			i.mi {
				@include media-breakpoint-down(sm) {
					font-size: 2rem;
					line-height: 2rem;
				}
			}
		}
	}
	&:hover {
		background-color: $gray-100;
	}
	&.active {
		background-color: lighten($primary, 4%);
		&:hover {
			background-color: lighten($primary, 0%);
		}
		div.body {
			.title {
				color: $white;
			}
			.badge.badge-light {
				background-color: lighten($primary, 8%);
				color: lighten($primary, 38%);
			}
		}
	}
	> div.body {
		width: 100%;
		@include media-breakpoint-down(sm) {
			padding: 0 $spacer-xs;
		}
		.badge {
			display: inline-block;
			line-height: 1;
			@include font-size($font-size-sm);
			border-radius: 0.2rem;
			padding: 2px 5px 4px;
			margin-top: 0;
			font-weight: $font-weight-normal;
			font-style: italic;
			@include media-breakpoint-down(sm) {
				@include font-size($font-size-sm * 0.6);
			}
		}
		.title {
			display: block;
			line-height: 1.2;
			@include media-breakpoint-down(sm) {
				@include font-size($font-size-sm * 0.8);
				@include text-truncate();
			}
		}
	}
}
