.toasts {
	position: fixed;
	bottom: 100px;
	right: $spacer-sm;
	z-index: 9999999999;
}

.toast {
	min-width: $toast-max-width;
	max-width: $toast-max-width;
	overflow: hidden; // cheap rounded corners on nested items
	@include font-size($toast-font-size);
	color: $toast-color;
	background-color: $toast-background-color;
	background-clip: padding-box;
	border: $toast-border-width solid $toast-border-color;
	box-shadow: $toast-box-shadow;
	backdrop-filter: blur(5px);
	opacity: 0;
	@include border-radius($toast-border-radius);

	&:hover {
		&.hover {
			cursor: pointer;
		}
	}

	&:not(:last-child) {
		margin-bottom: $toast-padding-x;
	}

	&.showing {
		opacity: 1;
	}

	&.show {
		display: block;
		opacity: 1;
	}

	&.hide {
		display: none;
	}
	&.danger {
		.toast-header {
			background-color: $danger;
			color: $white;
		}
	}
	&.warning {
		.toast-header {
			background-color: $yellow;
			color: $white;
		}
	}
	&.success {
		.toast-header {
			background-color: $success;
			color: $white;
		}
	}
}

.toast-header {
	display: flex;
	align-items: center;
	padding: $toast-padding-y $toast-padding-x;
	color: $toast-header-color;
	background-color: $toast-header-background-color;
	background-clip: padding-box;
	border-bottom: $toast-border-width solid $toast-header-border-color;
}

.toast-body {
	padding: $toast-padding-x; // apply to both vertical and horizontal
	p {
		line-height: 1.4;
		margin: 0;
	}
}
