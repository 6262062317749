.spinner {
	width: 3rem;
	height: 3rem;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -1.5rem;
	margin-left: -1.5rem;
	&.spinner2 {
		border: 4px solid transparent;
		border-top-color: $primary;
		border-bottom-color: $primary;
		border-radius: 50%;
		animation: rotation 0.8s ease infinite;
	}
}

// ANIMATIONS
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
