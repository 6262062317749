#root {
	> #main {
		> .dashboard {
			&.has-navbar {
				#user-alerts {
					padding-left: $navbar-w-collapsed;
					@include media-breakpoint-down(sm) {
						padding-left: $navbar-w-collapsed-sm;
					}
				}
				&.navbar-opened {
					#user-alerts {
						padding-left: $navbar-w;
						@include media-breakpoint-down(sm) {
							padding-left: $navbar-w-sm;
						}
					}
				}
			}
		}
	}
}

#user-alerts {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9998;
}

.user-alert {
	// height: $user_alert_h;
	padding: $spacer-sm $spacer-md;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: hidden;
	align-items: center;
	align-content: center;
	background-color: $primary;
	color: $white;
	width: 100%;
	// @include media-breakpoint-down(sm) {
	// 	padding: $spacer-xs $spacer;
	// }
	p {
		margin: 0;
		padding: 0 $spacer 0 0;
		width: 95%;
		line-height: 1.4;
		font-weight: $font-weight-bold;
		font-family: $headings-font-family;
		font-style: $headings-font-style;
		font-weight: $headings-font-weight;
		line-height: $headings-line-height;
		// @include media-breakpoint-down(sm) {
		// 	width: 85%;
		// 	font-size: $font-size-sm;
		// }
	}
	button {
		background: transparent;
		border: none;
		color: $white;
		width: 5%;
		padding: 0;
		// @include media-breakpoint-down(sm) {
		// 	width: 15%;
		// }
		i {
			display: block;
			line-height: $user_alert_h;
		}
	}
	@each $color, $value in $theme-colors {
		&.#{$color} {
			background-color: $value;
			color: color-yiq($value);
			button {
				color: color-yiq($value);
			}
		}
	}
}
