html,
body,
#root {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

html,
body {
	// overflow: hidden;
}

body {
	font-family: $font-family-sans-serif;
	line-height: $line-height-base;
	color: $body-color;
	font-size: $font-size-base;
	@include media-breakpoint-down(md) {
		font-size: $font-size-md;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $headings-font-family;
	margin: 0;
	line-height: 1.4;
	font-weight: 700;
}

#root {
	background-color: $gray-100;
	// overflow: hidden;
	> #main {
		height: 100%;
		width: 100%;
		> .dashboard {
			width: 100%;
			&.choose,
			&.chat,
			&.reservations,
			&.tables {
				height: 100%;
				> .main {
					padding-bottom: 0;
				}
			}
		}
	}
}
