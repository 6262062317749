@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../../font/MaterialIcons-Regular.eot); /* For IE6-8 */
	src: local('Material Icons'), local('MaterialIcons-Regular'),
		url(../../font/MaterialIcons-Regular.woff2) format('woff2'),
		url(../../font/MaterialIcons-Regular.woff) format('woff'),
		url(../../font/MaterialIcons-Regular.ttf) format('truetype');
}

@mixin material-icon() {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
	// cursor: default;
}

.mi {
	@include material-icon();
}
