.choose-ui {
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	> section {
		width: 50%;
		height: 100%;
		overflow: auto;

		> ul,
		> div {
			padding: $spacer-sm;
		}
		> a.button-link {
			padding-bottom: $spacer-lg;
			@include font-size($font-size-sm);
			color: $gray-600;
		}
		> ul {
			list-style: none;
			margin: 0;
			padding-bottom: 0;
			> li {
				margin-bottom: $spacer-xs;
			}
		}

		&:last-child {
			background-color: $gray-300;
		}
		aside.empty {
			margin-top: 0;
			> i {
				color: $gray-300;
				background-color: $gray-500;
			}
			> h3 {
				color: $gray-500;
			}
		}
	}
	&.centered {
		> section {
			width: 50%;
			background-color: $gray-100;
			margin: 0 auto;
			padding-top: 20px;
		}
	}
}

.list-ui {
	padding: $spacer 0;
	@include media-breakpoint-down(sm) {
		padding: 0;
	}
	> div {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		> ul {
			list-style: none;
			margin: 0;
			padding: 0;
			> li {
				margin-bottom: $spacer-sm;
				@include media-breakpoint-down(sm) {
					margin-bottom: 0;
					border-bottom: $gray-300 1px solid;
				}
			}
		}
		> .button {
			margin-top: $spacer * 2;
		}
	}
}
