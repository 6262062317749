.reservation-receipt-ui {
	background-color: $white;
	font-size: 2.7rem;
	color: $black;
	padding: 3rem;
	text-align: center;
	width: 1280px;
	height: 1280px;
	top: -9999rem;

	> div {
		width: 100%;
		height: 100%;
		> div {
			border: 0.5rem #afafaf dotted;
			padding: 3rem;
			height: 100%;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			> div {
				width: 100%;
			}
		}
	}
	img {
		width: 35%;
		height: auto;
		margin-bottom: 1rem;
	}
	h1 {
		line-height: 1.2;
		font-size: 180%;
		small {
			font-size: 30%;
			text-align: center;
			text-transform: uppercase;
			font-weight: $font-weight-normal;
			display: block;
			color: #757575;
		}
	}
	small.ref {
		font-size: 60%;
		span {
			color: #757575;
			text-transform: uppercase;
			font-weight: $font-weight-bold;
			font-size: 80%;
		}
	}
	p {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		line-height: 1.2;
		align-items: center;
		margin-top: 1.6rem;
		padding: 0 1rem;
		span {
			display: block;
			width: 100%;
			font-size: 50%;
			color: #757575;
			text-transform: uppercase;
			font-weight: $font-weight-bold;
			margin-bottom: 0.4rem;
		}
		strong {
			display: block;
			width: 100%;
		}
	}
}
