@import 'style/functions';
@import 'style/variables';
@import 'style/mixins';
@import 'style/reboot';

@import 'style/icons';
@import 'style/main';
@import 'style/button';
@import 'style/toast';
@import 'style/spinner';
@import 'style/login-ui';
@import 'style/navbar';
@import 'style/topbar';
@import 'style/archive';
@import 'style/form';
@import 'style/react-select';
@import 'style/categories';
@import 'style/cart';
@import 'style/product';
@import 'style/grid';
@import 'style/list';
@import 'style/card';
@import 'style/popover';
@import 'style/tables';
@import 'style/reservations-grid';
@import 'style/stats';
@import 'style/user-alert';
@import 'style/alert';
@import 'style/invoice';
@import 'style/chat';
@import 'style/printer';
@import 'style/receipt';
@import 'style/items-management';

@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

.react-select-filter {
	width: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.react-select-filter-inner__control {
	width: 250px;
}

.react-datepicker-popper {
	z-index: 1000;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-5px);
	}
	100% {
		transform: translatey(0px);
	}
}

.order-name-text {
	font-family: 'Roboto Mono', monospace !important;
	font-weight: normal;
	letter-spacing: 0.1em;
	margin-left: 15px;
}

// div.table-ui > div > table td:not(.more-cell) > .button.button-done {
// 	width: 250px !important ;
// }

.button-done {
	display: flex;
	justify-content: center;
	align-items: center;
	i {
		display: inline;
		padding: 0;
		margin: 0;
	}
	span {
		margin-left: 20px;
		display: inline;
	}
}

.w-100 {
	width: 100% !important;
}

.showHover {
	&:hover {
		cursor: pointer;
	}
}

.cookies-agreement {
	position: fixed;
	bottom: 0;
	width: 620px;
	left: 50%;
	margin-left: -310px;
	z-index: 9999;
	background-color: $gray-300;
	padding: $spacer-md;
	@include border-top-radius($border-radius);
	@include box-shadow(0 0.125rem 0.35rem rgba(108, 117, 125, 0.25));
	@include media-breakpoint-down('sm') {
		width: 90%;
		margin-left: -45%;
	}
	p {
		margin-bottom: $spacer-sm;
		font-size: $font-size-sm;
		color: $black;
	}
	footer {
		display: flex;
	}
}
