.recharts-cartesian-axis-tick-value {
	// color: red;
	// @include text-truncate();
}

.recharts-text {
	@include text-truncate();
	tspan {
		display: inline;
	}
}

.ui-stats {
	display: flex;
	flex-wrap: wrap;
	padding: $spacer;
	@include media-breakpoint-down(lg) {
		padding: $spacer-xs;
	}
	@include media-breakpoint-down(sm) {
		padding: 0;
	}
	> .stat {
		width: 50%;
		padding: $spacer;
		@include media-breakpoint-down(lg) {
			padding: $spacer-xs;
		}
		@include media-breakpoint-down(md) {
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
			padding: 0;
		}
		&.stat-wide {
			width: 100%;
			> div {
				> div.wrapper {
					padding-top: 23%;
				}
			}
		}
		@for $z from 1 through 20 {
			&.stat-h-#{$z} {
				> div {
					> div.wrapper {
						padding-top: 4.5% * $z;
					}
				}
			}
		}
		> div {
			background-color: $white;
			@include box-shadow($box-shadow-sm);
			@include border-radius($border-radius-sm);
			padding: $spacer;
			@include media-breakpoint-down(lg) {
				padding: $spacer-xs;
			}
			@include media-breakpoint-down(sm) {
				@include border-radius(0);
			}
			> h1 {
				font-size: $font-size-lg;
				text-align: center;
				color: $gray-600;
				margin: 0;
				border-bottom: $gray-200 2px solid;
				margin-left: -$spacer;
				margin-right: -$spacer;
				padding-bottom: $spacer;
				@include media-breakpoint-down(lg) {
					margin-left: -$spacer-xs;
					margin-right: -$spacer-xs;
					padding-bottom: $spacer-xs;
					padding-top: $spacer-xxs;
				}
				@include media-breakpoint-down(sm) {
					margin-left: 0;
					margin-right: 0;
					padding-top: $spacer-sm;
					font-size: $font-size-base;
				}
				span.badge {
					@include border-radius(100px);
					margin-left: $spacer-sm;
					display: inline-block;
					font-size: 0.9rem;
					padding-left: $spacer-sm;
					padding-right: $spacer-sm;
					line-height: 1;
					padding-top: $spacer-xs;
					padding-bottom: $spacer-xs;
				}
			}
			> div.wrapper {
				width: 100%;
				padding-top: 45%;
				// background-color: $red;
				position: relative;
				margin-top: $spacer;
				@include media-breakpoint-down(lg) {
					margin-top: $spacer-xs;
				}
				.recharts-responsive-container {
					position: absolute;
					top: 0;
					left: 0;
					.recharts-surface {
						font-size: $font-size-sm;
						line-height: 1.7;
					}
				}
			}
		}
	}
}
