$unit_size: 10px;

.overmouse {
	background-color: darken($primary, 20);
	pointer-events: none;
}
.floor {
	background-color: $white;
	border-width: 1px;
	border-color: $primary;
	border-radius: 5px;
	border-style: solid;
}
.tables-component {
	width: 100%;
	height: 100%;
	position: relative;
	// background-color: $red;
	overflow: hidden;
	cursor: move; /* fallback: no `url()` support or images disabled */
	cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
	cursor: -moz-grab; /* Firefox 1.5-26 */
	cursor: grab; /* W3C standards syntax, should come least */
	> .conventions {
		position: absolute;
		bottom: $spacer;
		left: $spacer;
		z-index: 3;
		@include media-breakpoint-down(sm) {
			bottom: $spacer-sm;
			left: $spacer-sm;
		}
		> span {
			display: block;
			@include media-breakpoint-down(sm) {
				line-height: 1;
			}
			i {
				width: $spacer-sm;
				height: $spacer-sm;
				border-radius: 50%;
				@include media-breakpoint-down(sm) {
					width: $spacer-xs;
					height: $spacer-xs;
				}
			}
			b {
				font-size: $font-size-sm;
				@include media-breakpoint-down(sm) {
					font-size: $font-size-sm * 0.8;
				}
			}
		}
	}
	&.grabbing {
		cursor: grabbing;
	}
	&.selecting {
		.center {
			button.table {
				&:not(.selected) {
					opacity: 0.4;
					&:hover,
					&:focus {
						opacity: 1;
					}
				}
			}
		}
	}
	.center {
		width: 4px;
		height: 4px;
		// background-color: $black;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -2px;
		margin-left: -2px;
		@include transition(margin 0.15s ease-out);
		.bgimage {
			background-image: url('../../img/tile.png');
			width: 8000em;
			height: 8000em;
			position: absolute;
			top: -4000em;
			left: -4000em;
			opacity: 0.8;
			background-position: left top;
		}
		&:before,
		&:after {
			content: ' ';
			display: block;
			position: absolute;
			background-color: $white;
			z-index: 4;
			mix-blend-mode: difference;
			// filter: invert(100%);
		}
		&:before {
			width: 19px;
			height: 1px;
			left: -10px;
			top: -1px;
		}
		&:after {
			height: 19px;
			width: 1px;
			top: -10px;
			left: -1px;
		}
		button.table {
			position: absolute;
			z-index: 1;
			padding: 0;
			border-radius: $border-radius-xs;
			border: none;
			& * {
				user-select: none;
			}
			b {
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				z-index: 4;
				overflow: hidden;
				width: 43px;
				margin-top: -30px;
				margin-left: -2px;
				pointer-events: none;
				&:before {
					content: ' ';
					background-color: rgba(darken($primary, 10%), 0.9);
					width: 3px;
					height: 30px;
					display: block;
					float: left;
				}
				span {
					display: block;
					color: $white;
					background-color: rgba(darken($primary, 10%), 0.8);
					text-shadow: 1px 1px 0 darken($primary, 10%);
					font-size: 14px;
					line-height: 20px;
					padding: 0 5px;
					text-align: center;
					float: left;
					width: 40px;
					@include border-right-radius(5px);
					margin-left: -1px;
				}
			}
			em {
				display: block;
				position: absolute;
				left: 0;
				top: 100%;
				z-index: 4;
				overflow: hidden;
				width: $spacer-lg;
				height: $spacer-lg;
				line-height: $spacer-lg;
				margin-left: -($spacer-lg * 0.15);
				margin-top: -($spacer-lg * 0.9);
				font-style: normal;
				font-weight: $font-weight-bold;
				font-size: $font-size-sm * 0.9;
				color: $white;
				border-radius: 50%;
				box-shadow: inset 0 0 0 3px $primary;
				color: darken($primary, 10%);
				background: lighten($primary, 35%);
			}
			&:before {
				box-shadow: inset 0 0 0 0.5px $primary;
				content: ' ';
				display: block;
				width: 100%;
				height: 100%;
				border-radius: $border-radius-xs;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				background: lighten($primary, 35%);
			}
			&:focus,
			&:active {
				background-color: lighten($primary, 10%);
				z-index: 2;
				outline-style: none;
			}
			i {
				display: block;
				position: absolute;
				z-index: 1;
				&.t,
				&.b {
					width: 100%;
					height: $unit_size;
					background-size: $unit_size auto;
					background-repeat: repeat-x;
				}
				&.l,
				&.r {
					height: 100%;
					width: $unit_size;
					background-size: auto $unit_size;
					background-repeat: repeat-y;
				}
				&.l {
					left: -($unit_size / 2);
					top: 0;
					background-image: url('../../img/tables-left.png');
					background-position: top right;
				}
				&.r {
					right: -($unit_size / 2);
					top: 0;
					background-image: url('../../img/tables-right.png');
				}
				&.t {
					left: 0;
					top: -($unit_size / 2);
					background-image: url('../../img/tables-top.png');
					background-position: bottom left;
				}
				&.b {
					left: 0;
					bottom: -($unit_size / 2);
					background-image: url('../../img/tables-bottom.png');
					background-position: top left;
				}
			}
			strong {
				color: $gray-600;
				display: block;
				position: absolute;
				top: 131%;
				left: -20%;
				width: 140%;
				text-align: center;
				font-size: 10px;
				line-height: 11px;
				z-index: 4;
			}
			&[data-s='1'] {
				i {
					&.t,
					&.r,
					&.b {
						display: none;
					}
				}
			}
			&[data-s='2'] {
				i {
					&.t,
					&.b {
						display: none;
					}
				}
			}
			&[data-s='3'] {
				i {
					&.t {
						display: none;
					}
				}
			}
			@each $color, $value in $table-colors {
				&.#{$color} {
					&:before {
						background: lighten($value, 29%);
					}
					&:focus,
					&:active {
						background-color: lighten($value, 20%);
					}
					b {
						&:before {
							background-color: rgba(darken($value, 10%), 0.9);
						}
						span {
							background-color: rgba(darken($value, 10%), 0.8);
							text-shadow: 1px 1px 0 darken($value, 10%);
						}
					}
					em {
						box-shadow: inset 0 0 0 3px $value;
						color: darken($value, 10%);
						background: lighten($value, 29%);
					}
					i {
						&.l {
							background-image: url('../../img/tables-left-#{$color}.png');
						}
						&.r {
							background-image: url('../../img/tables-right-#{$color}.png');
						}
						&.t {
							background-image: url('../../img/tables-top-#{$color}.png');
						}
						&.b {
							background-image: url('../../img/tables-bottom-#{$color}.png');
						}
					}
				}
			}
		}
		&[data-zoom='6'],
		&[data-zoom='5'] {
			button.table {
				strong {
					font-size: 12px;
					line-height: 14px;
				}
			}
		}
		&[data-zoom='8'],
		&[data-zoom='7'] {
			button.table {
				em {
					width: $spacer-xl;
					height: $spacer-xl;
					line-height: $spacer-xl;
					margin-left: -($spacer-xl * 0.15);
					margin-top: -($spacer-xl * 0.9);
					font-size: $font-size-sm;
				}
				strong {
					font-size: 14px;
					line-height: 16px;
				}
			}
		}
		// @for $z from 1 through 20 {
		// 	&[data-zoom='#{$z}'] {
		// 		$zz: $z;
		// 		.bgimage {
		// 			background-size: ($unit_size * $zz) ($unit_size * $zz);
		// 		}
		// 		button.table {
		// 			border-radius: $border-radius-xs * $zz;
		// 			&:before {
		// 				border-radius: $border-radius-xs * $zz;
		// 				box-shadow: inset 0 0 0 (0.6px * $zz) $primary;
		// 			}
		// 			em {
		// 				box-shadow: inset 0 0 0 (0.6px * $zz) $primary;
		// 			}
		// 			@each $color, $value in $table-colors {
		// 				&.#{$color} {
		// 					&:before {
		// 						box-shadow: inset 0 0 0 (0.6px * $zz) $value;
		// 					}
		// 					em {
		// 						box-shadow: inset 0 0 0 (0.6px * $zz) $value;
		// 					}
		// 				}
		// 			}
		// 			@for $i from 1 through 9 {
		// 				&[data-w='#{$i}'] {
		// 					width: ($unit_size * $zz) * $i;
		// 					margin-left: -(((($unit_size * $zz) * $i) * 0.5));
		// 				}
		// 			}
		// 			@for $i from 1 through 2 {
		// 				&[data-h='#{$i}'] {
		// 					height: ($unit_size * $zz) * $i;
		// 					margin-top: -((($unit_size * $zz) * $i) * 0.5);
		// 				}
		// 			}
		// 			&[data-r='45'] {
		// 				transform: rotate(45deg);
		// 				b {
		// 					transform: rotate(-45deg);
		// 					margin-top: -39px;
		// 					margin-left: -17px;
		// 				}
		// 				em {
		// 					transform: rotate(-45deg);
		// 				}
		// 			}
		// 			&[data-r='90'] {
		// 				transform: rotate(90deg);
		// 				b {
		// 					transform: rotate(-90deg);
		// 					margin-top: -35px;
		// 					margin-left: -35px;
		// 				}
		// 				em {
		// 					transform: rotate(-90deg);
		// 					left: auto;
		// 					right: 0;
		// 				}
		// 			}
		// 			&[data-r='135'] {
		// 				transform: rotate(135deg);
		// 				b {
		// 					transform: rotate(-135deg);
		// 					margin-top: -19px;
		// 					margin-left: -46px;
		// 				}
		// 				em {
		// 					transform: rotate(-135deg);
		// 					left: auto;
		// 					right: 0;
		// 				}
		// 			}
		// 			@for $i from 0 through 20 {
		// 				@for $d from 0 through 9 {
		// 					&[data-x='#{$i}.#{$d}'] {
		// 						left: (($unit_size * $zz) * ($i + ($d / 10)));
		// 					}
		// 					&[data-x='-#{$i}.#{$d}'] {
		// 						left: -(($unit_size * $zz) * ($i + ($d / 10)));
		// 					}
		// 					&[data-y='#{$i}.#{$d}'] {
		// 						top: (($unit_size * $zz) * ($i + ($d / 10)));
		// 					}
		// 					&[data-y='-#{$i}.#{$d}'] {
		// 						top: -(($unit_size * $zz) * ($i + ($d / 10)));
		// 					}
		// 				}
		// 			}
		// 			i {
		// 				&.t,
		// 				&.b {
		// 					height: ($unit_size * $zz);
		// 					background-size: ($unit_size * $zz) auto;
		// 				}
		// 				&.l,
		// 				&.r {
		// 					width: ($unit_size * $zz);
		// 					background-size: auto ($unit_size * $zz);
		// 				}
		// 				&.l {
		// 					left: -(($unit_size * $zz) / 2);
		// 				}
		// 				&.r {
		// 					right: -(($unit_size * $zz) / 2);
		// 				}
		// 				&.t {
		// 					top: -(($unit_size * $zz) / 2);
		// 				}
		// 				&.b {
		// 					bottom: -(($unit_size * $zz) / 2);
		// 				}
		// 			}
		// 			@for $i from 1 through 9 {
		// 				&[data-w='#{$i}'] {
		// 					&:not([data-s='1']):not([data-s='2']):not([data-s='3']) {
		// 						&[data-o='1'] {
		// 							i {
		// 								&.r {
		// 									display: none;
		// 								}
		// 							}
		// 						}
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}
}

.adding-table {
	position: absolute;
	top: 0;
	left: 0;
	top: $topbar-h;
	z-index: 99;
	width: 360px;
	left: calc(50% - 180px);
	display: flex;
	flex-wrap: wrap;
	@include media-breakpoint-down(sm) {
		// position: relative;
		width: 100%;
		left: 0;
		top: 0;
	}
	> p {
		display: block;
		width: 100%;
		margin: 0;
		text-align: center;
		padding: $spacer-xs 0;
	}
	> .button {
		width: 50%;
	}
}

.controls {
	position: absolute;
	bottom: 110px;
	right: $spacer;
	z-index: 30;
	width: $spacer-xxl;
	height: $spacer-xxl * 3.1;
	.recenter {
		margin-bottom: $spacer-xxl * 0.1;
	}
	button {
		border: none;
		background-color: $white;
		width: $spacer-xxl;
		height: $spacer-xxl;
		padding: 0;
		i {
			display: block;
			width: $spacer-xxl;
			height: $spacer-xxl;
			line-height: $spacer-xxl;
			text-align: center;
		}
	}
}

.floors-holder {
	width: 320px;
	display: flex;
	flex-wrap: wrap;
	> div.input-search {
		margin-right: 10px;
		position: relative;
		> .form-control {
			width: 100%;
			height: 100%;
			border: $gray-400 1px solid;
			outline-style: none;
			padding: 0 $spacer-xl 0 $spacer-xs;
			@include border-radius($border-radius-sm);
			&:focus,
			&:active {
				border-color: $primary;
			}
		}
		> .mi {
			position: absolute;

			color: $gray-400;
			top: 50%;
			right: 5px;
			width: 24px;
			margin-top: -12px;
		}
	}
	> * {
		width: 155px;
	}
}

.dashboard {
	> .main {
		> div.tables-map-holder {
			height: 100%;
			position: relative;
			.floors-holder {
				position: absolute;
				top: $spacer;
				right: $spacer;
				z-index: 99;
			}
		}
	}
}

.modal-form {
	.form-ui {
		.floors-holder {
			position: absolute;
			top: $spacer-xs;
			right: $spacer-xs;
			z-index: 99;
		}
		aside.empty {
			margin-top: 0;
			padding: $spacer 0 0 0;
		}
	}
}
