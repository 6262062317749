.categories-ui {
	width: 100%;
	margin: 0;
	padding: 0 $spacer-lg;
	position: relative;
	@include media-breakpoint-down(md) {
		margin: 0;
		padding: 0 $spacer-sm;
	}
	> div {
		width: 100%;
		ul {
			list-style: none;
			display: block;
			padding: 0;
			margin: 0;
			margin-right: -$spacer-md;
			@include media-breakpoint-down(sm) {
				margin-right: -$spacer-xs;
			}
			&:after {
				content: ' ';
				display: block;
				clear: both;
			}
			> li {
				text-align: center;
				padding: 0;
				padding-top: $spacer-md;
				padding-right: $spacer-sm;
				display: block;
				float: left;
				width: 16.612%;
				max-width: 240px;
				@include media-breakpoint-down(md) {
					padding-top: $spacer-sm;
					padding-right: $spacer-xs;
				}
				@include media-breakpoint-down(sm) {
					width: 33.3264% !important;
					padding-top: $spacer-xs;
					padding-right: $spacer-xxs;
				}
				@include media-breakpoint-down(xs) {
					width: 50% !important;
				}
				&:nth-child(even) {
					@include media-breakpoint-down(xs) {
						padding-right: 0 !important;
					}
				}
				&.back {
					max-width: 80px !important;
				}
			}
			&.count-1 > li {
				width: 100%;
			}
			&.count-2 > li {
				width: 50%;
			}
			&.count-3 > li {
				width: 33.3264%;
				@include media-breakpoint-down(sm) {
					width: 50%;
				}
			}
			&.count-4 > li {
				width: 25%;
			}
			&.count-5 > li,
			&.count-10 > li {
				width: 20%;
			}
			&.count-7 > li,
			&.count-13 > li,
			&.count-14 > li {
				width: 14.2571%;
			}
			&.count-8 > li,
			&.count-15 > li,
			&.count-16 > li {
				width: 12.5%;
			}
			&.count-9 > li,
			&.count-17 > li,
			&.count-18 > li {
				width: 11.08%;
			}
			&.count-10 > li,
			&.count-20 > li,
			&.count-19 > li {
				width: 10%;
			}
			button {
				display: block;
				// @include border-radius($border-radius);
				@include border-radius($border-radius-sm);
				// background: $gray-500;
				// @include gradient-bg($gray-500);
				background: transparent;
				color: $body-color;
				font-family: $headings-font-family;
				margin: 0;
				line-height: 1.4;
				font-weight: 700;
				width: 100%;
				border: $border-color $border-width solid;

				padding: $spacer $spacer-sm;
				@include font-size(1rem);
				outline-style: none;
				@include media-breakpoint-down(lg) {
					padding: $spacer-sm $spacer-xs;
					@include font-size(0.9rem);
					// width: 160px;
				}
				@include media-breakpoint-down(md) {
					@include font-size(0.8rem);
					padding: $spacer-xs $spacer-xs;
				}
				span {
					display: block;
					line-height: 1.3;
					@include text-truncate();
					@include media-breakpoint-down(sm) {
					}
				}
				&.active {
					background-color: $primary;
					border-color: $primary;
					color: $white;
					// @include gradient-bg($primary);
				}
				&.back-button {
					position: relative;
					background-color: $gray-600;
					border-color: $gray-600;
					color: $white;
					padding-left: 0;
					padding-right: 0;
					width: 100%;
					i {
						position: absolute;
						top: 50%;
						left: 50%;
						margin-top: -12px;
						margin-left: -12px;
					}
					span {
						color: transparent;
						width: 1px;
						overflow: hidden;
					}
				}
			}
		}
	}
}

.subcategories-ui {
	width: 100%;
	margin: 0;
	padding: 0 $spacer-lg;
	position: relative;
	@include media-breakpoint-down(md) {
		margin: 0;
		padding: 0 $spacer-sm;
	}
	> div {
		width: 100%;
		ul {
			list-style: none;
			display: block;
			padding: 0;
			margin: $spacer 0 0 0;
			margin-right: -$spacer-md;
			@include media-breakpoint-down(sm) {
				margin-right: -$spacer-xs;
			}
			li {
				display: inline-block;
				margin-right: $spacer-xs;
				@include media-breakpoint-down(sm) {
					margin-right: 0;
				}
				// &:after {
				// 	content: ' - ';
				// 	display: inline-block;
				// 	vertical-align: middle;
				// 	margin-left: $spacer-xs;
				// 	color: $gray-400;
				// 	@include media-breakpoint-down(sm) {
				// 		margin-left: $spacer-xxxs;
				// 	}
				// }
				&:last-child {
					// &:after {
					// 	display: none;
					// }
				}
				button {
					border: none;
					background: transparent;
					outline-style: none;
					span {
						color: $gray-700;
						font-weight: $font-weight-bold;
						display: block;
						padding: 0 $spacer-sm $spacer-xxs;
						position: relative;
						@include media-breakpoint-down(sm) {
							padding: 0 $spacer-xs $spacer-xxs;
						}
						&:after {
							content: ' ';
							display: block;
							height: 3px;
							width: 100%;
							background-color: transparent;
							position: absolute;
							bottom: 0;
							left: 0;
						}
					}
					&:hover,
					&:focus {
						span {
							color: $secondary;
							&:after {
								background-color: $secondary;
							}
						}
					}
					&.active {
						span {
							color: $primary;
							&:after {
								background-color: $primary;
							}
						}
					}
				}
			}
		}
	}
}
