.printers-ui {
	.form-ui {
		padding-bottom: $spacer;
		h1 {
			margin-bottom: $spacer-sm;
		}
	}
	.printer {
		display: flex;
		flex-wrap: wrap;
		padding: ($spacer / 2) ($spacer);
		&:hover {
			background-color: $gray-100;
		}
		p {
			width: 70%;
			margin: 0;
			span,
			i {
				display: inline-block;
				vertical-align: middle;
			}
			i {
				color: $gray-500;
				font-size: 40px;
				margin-right: $spacer-sm;
			}
		}
		.button {
			width: 30%;
		}
		&.selected {
			background-color: lighten($primary, 38%);
			p {
				i {
					color: $primary;
				}
			}
		}
	}
	footer {
		text-align: center;
		padding: $spacer 0;
		p {
			margin: 0;
			> * {
				display: inline-block;
				vertical-align: middle;
				padding: 0 $spacer-xs;
				strong {
					color: $black;
					font-family: $headings-font-family;
				}
			}
			> a {
				> * {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
}
