#PRINT_INVOICE {
	width: 960px;
	background-color: $white;
	padding: 60px 30px 0;
	text-align: center;
	font-size: 32px;
	color: $black;
	overflow: hidden;
	font-family: 'Courier New', Courier, monospace;

	h3 {
		font-family: 'Courier New', Courier, monospace;
		font-size: 42px;
	}
	p {
		span {
			display: block;
			text-align: center;
		}
	}
	table {
		margin-bottom: 60px;
		width: 900px;
		td,
		th {
			text-align: left;
			padding: 10px;
		}
	}
}
