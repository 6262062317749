a.card {
	cursor: pointer;
	&:hover {
		@include box-shadow($box-shadow-darker);
	}
}

.poster {
	display: block;
	background-color: $gray-200;
	padding-top: 130%;
	overflow: hidden;
	position: relative;
	z-index: 2;
	@include background-cover();
	> .mi {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 80px;
		color: $gray-400;
		width: 80px;
		height: 80px;
		margin-top: -40px;
		margin-left: -40px;
		@include media-breakpoint-down(sm) {
			font-size: 50px;
			width: 50px;
			height: 50px;
			margin-top: -25px;
			margin-left: -25px;
		}
	}
}

.info-card {
	display: flex;
	flex-wrap: wrap;
	> aside {
		width: 23%;
		.poster {
			@include border-radius($border-radius-sm);
		}
	}
	> div {
		width: 77%;
		padding-left: $spacer-lg;
		> strong {
			color: $headings-color;
			font-family: $headings-font-family;
			font-weight: $headings-font-weight;
			display: block;
			@include font-size($font-size-lg);
			margin-bottom: $spacer-xxs;
		}
		> em {
			display: block;
			margin-bottom: $spacer-xxs;
			font-style: normal;
		}
		> .badges {
			margin-bottom: $spacer-xxs;
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				b {
					color: $headings-color;
				}
			}
		}
	}
}

div.progress-bar {
	margin-top: $spacer-sm;
	> b {
		width: 100%;
		background-color: $gray-200;
		position: relative;
		height: $spacer-xs;
		display: block;
		@include border-radius($spacer-xl);
		overflow: hidden;
		i {
			display: block;
			height: 100%;
			background-color: $primary;
		}
	}
	> strong,
	> small {
		display: inline-block;
		vertical-align: middle;
		padding-right: $spacer-xs;
		margin-top: $spacer-xxs;
		line-height: $spacer-lg;
	}

	& + div.progress-bar {
	}
	&:nth-child(2) {
		> b {
			i {
				background-color: $primary;
			}
		}
	}
	&:nth-child(3) {
		> b {
			i {
				background-color: $warning;
			}
		}
	}
	&:nth-child(4) {
		> b {
			i {
				background-color: $danger;
			}
		}
	}
	&:nth-child(5) {
		> b {
			i {
				background-color: $success;
			}
		}
	}
}

.card {
	display: block;
	@include box-shadow($box-shadow);
	background-color: $white;
	height: 100%;
	@include border-radius($border-radius-sm);
	overflow: hidden;
	@include media-breakpoint-down(sm) {
		@include border-radius(0);
	}
	> pre.chrono {
		font-weight: bold;
		@include font-size($font-size-lg);
		text-align: center;
		width: 80%;
		background-color: $body-color;
		color: $white;
		text-align: center;
		margin-left: 10%;
		@include border-radius($spacer-xxl);
		margin-top: -($spacer-xxl);
		position: relative;
		z-index: 3;
	}
	> div.body {
		padding: $spacer $spacer-md;
		@include media-breakpoint-down(sm) {
			padding: $spacer-sm $spacer;
		}
		> * {
			margin: 0;
			margin-top: $spacer-xs;
			display: block;
			&:first-child {
				margin-top: 0;
			}
		}
		> .title {
			font-weight: $headings-font-weight;
			font-family: $headings-font-family;
			color: $headings-color;
			@include font-size($h5-font-size);
			line-height: $h3-font-size;
			max-height: $h3-font-size * 3;
			overflow: hidden;
			@include media-breakpoint-down(sm) {
				@include font-size($font-size-base);
				line-height: $h4-font-size;
				max-height: $h4-font-size * 3;
			}
		}
		> em.date {
			display: block;
			position: relative;
			font-style: normal;
			padding-left: $spacer-xl;
			@include font-size($font-size-sm);
			.mi {
				position: absolute;
				top: 0;
				left: 0;
				color: $gray-200;
			}
			span,
			time {
				display: block;
				line-height: 1.5;
			}
		}
	}
	&.card-horizontal {
		display: flex;
		flex-wrap: wrap;
		.poster {
			width: 33%;
			padding-top: 42%;
		}
		.body {
			width: 67%;
		}
	}
	&.vcard {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: $spacer-sm;
		position: relative;
		@include media-breakpoint-down(sm) {
			padding: $spacer-xs $spacer-xs $spacer;
			display: block;
		}
		> .avatar {
			width: 3rem;
			text-align: center;
			position: absolute;
			top: 50%;
			margin-top: -1.5rem;
			left: $spacer;
			@include media-breakpoint-down(sm) {
				position: static;
				margin-top: $spacer-xs;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: $spacer-xs;
			}
			span.img {
				width: 3rem;
				height: 3rem;
				margin-left: auto;
				margin-right: auto;
				@include media-breakpoint-down(sm) {
				}
				i.mi {
					color: darken($gray-400, 2%);
					font-size: 4rem;
					line-height: 3rem;
					text-align: center;
					width: 3rem;
					height: 3rem;
					margin-left: -0.5rem;
					@include media-breakpoint-down(sm) {
					}
				}
			}
			&.has-image {
				span.img {
					i.mi {
						display: none;
					}
				}
			}
		}
		> div.body {
			width: 60%;
			padding: 0 $spacer;
			padding-left: 4.5rem;
			@include media-breakpoint-down(sm) {
				padding: 0 $spacer;
				width: 100%;
				text-align: center;
			}
			> em {
				margin-top: 0%;
			}
			> .title {
				@include font-size($font-size-base);
				line-height: 1.5;
				font-family: $font-family-sans-serif;
				@include media-breakpoint-down(sm) {
				}
			}
		}
		> .button {
			max-width: 40%;
			width: 190px;
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-top: $spacer-xs;
				max-width: 240px;
				margin-right: auto;
				margin-left: auto;
			}
		}
		&.placecard {
			overflow: visible;
			> .avatar {
				span.img {
					i.mi {
						font-size: 2.3rem;
						width: 4rem;
						color: darken($gray-400, 4%);
					}
				}
			}
		}
		&.active {
			background-color: $gray-300;
		}
	}
}

.badges {
	b {
		display: inline-block;
		padding: 0.2rem 0.5rem;
		background-color: $orange;
		color: $white;
		font-weight: $font-weight-bold;
		@include font-size($font-size-base);
		@include border-radius($border-radius-sm);
		i,
		span {
			line-height: $spacer-lg;
			display: inline-block;
			vertical-align: middle;
		}
		&.ended {
			background-color: $gray-500;
		}
		&.running {
			background-color: $green;
		}
	}
}

.card-deck {
	.card {
	}

	@include media-breakpoint-up(sm) {
		display: flex;
		flex-flow: row wrap;
		padding-right: $spacer-sm;
		padding-left: $spacer-sm;
		.card {
			// Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
			flex: 1 0 0%;
			margin-right: $spacer-sm;
			margin-bottom: 0; // Override the default
			margin-left: $spacer-sm;
		}
	}
}
