.react-select {
	// z-index: 10;
	.react-select__control {
		border: $border-color 2px solid;
		@include border-radius($border-radius-sm);
		padding: 0;
		box-shadow: none;
		display: flex;
		// z-index: 99;
		&:hover {
			border-color: $gray-400;
		}
		&.react-select__control--is-focused {
			border-color: $gray-400;
			background-color: transparent;
			// @include gradient-bg($gray-150);
		}
		.react-select__value-container {
			padding: 0;
			padding-left: 2px;
			.react-select__placeholder {
				color: $body-color;
				// font-weight: $font-weight-bold;
				// @include font-size($font-size-sm);
				// text-transform: uppercase;
				text-align: left;
				display: block;
				// position: relative;
				margin: 0;
				height: $spacer-xxxl;
				line-height: $spacer-xxxl;
				min-width: 24%;
				@include font-size($font-size-lg);
				@include media-breakpoint-down(md) {
					@include font-size($font-size-sm);
				}
				@include media-breakpoint-down(md) {
					@include font-size($font-size-sm);
				}
			}
			.react-select__multi-value {
				height: $spacer-xxl;
				line-height: $spacer-xxl;
				@include border-radius($border-radius-sm);
				overflow: hidden;
				background-color: $gray-300;
				> div {
					line-height: $spacer-xl * 1.2;
					font-size: $font-size-base;
					&.react-select__multi-value__label {
						padding-left: $spacer-sm;
					}
				}
			}
			[class*='Input'] {
				padding-top: 0;
				padding-bottom: 0;
				margin: 1px 0;
			}
		}
	}
	.react-select__menu {
		z-index: auto;
		// position: static;
		margin: 0;
	}
}
