$r-row-h: 3rem;
$r-row-h-sm: 2rem;
$tables-col-w: 3rem;
$r-cell-w: 2.5rem;
$r-cell-w-sm: 1.8rem;
$r-padd: 0.3rem;
$seat-w: 10rem;
$seat-w-sm: 7rem;

.reservations-grid {
	padding: $spacer-sm;
	@include media-breakpoint-down(sm) {
		padding: $spacer-xs;
		// overflow: auto;
	}
	> div {
		padding-left: $tables-col-w;
		position: relative;
		// border: $gray-400 1px solid;
		overflow: hidden;
		border-radius: $border-radius * 0.8;
		@include box-shadow($box-shadow-sm);
		@include media-breakpoint-down(sm) {
			// min-width: 620px;
		}
		> div {
			&.tables {
				width: $tables-col-w;
				position: absolute;
				top: 0;
				left: 0;
				> section {
					> .r {
						// padding-right: $r-padd;
						strong {
							display: block;
							text-align: center;
							width: 100%;
							background-color: $primary;
							color: $white;
							// @include border-right-radius($border-radius);
							@include media-breakpoint-down(sm) {
								font-size: $font-size-sm;
							}
						}
					}
				}
			}
			&.hours {
				padding-top: $r-row-h;
				overflow: auto;
				width: 100%;
				position: relative;
				@include media-breakpoint-down(sm) {
					padding-top: $r-row-h-sm;
				}
				> header {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					> .r {
						> *.slot {
							&:first-child {
								time {
									transform: translateX(0) !important;
									text-align: left;
								}
							}
							&:nth-child(even) {
								time {
									@include media-breakpoint-down(md) {
										display: none;
									}
								}
							}
						}
					}
				}
				@for $z from 1 through 49 {
					&.cols-#{$z} {
						.r {
							// width: $r-cell-w * $z;
						}
						> * {
							> .r {
								> * {
									width: 100% / $z;
								}
								> button.reservation {
									@for $x from 1 through 21 {
										&.cols-#{$x} {
											width: (100% / $z) * $x;
										}
									}
								}
							}
						}
					}
				}
				@for $z from 1 through 49 {
					&.cols-#{$z} {
						> header,
						> section {
							@include media-breakpoint-down(md) {
								min-width: $r-cell-w * $z;
								// min-width: 0;
							}
							@include media-breakpoint-down(sm) {
								min-width: $r-cell-w-sm * $z;
							}
						}
					}
				}
			}
			> header {
				background-color: $gray-200;
			}
			> * {
				> .r {
					display: block;
					height: $r-row-h;
					// margin-bottom: $r-padd;
					width: 100%;
					position: relative;
					// overflow: hidden;
					@include media-breakpoint-down(sm) {
						height: $r-row-h-sm;
					}
					&.sep {
						height: $r-row-h / 2;
						background-color: $gray-200;
						@include media-breakpoint-down(sm) {
							height: $r-row-h-sm / 2;
						}
					}
					&:before {
						content: ' ';
						display: block;
						width: 100%;
						height: 1px;
						position: absolute;
						top: -1px;
						left: 0;
						background-color: $gray-200;
					}
					&:after {
						content: ' ';
						clear: both;
					}
					> * {
						height: $r-row-h;
						line-height: $r-row-h;
						display: block;
						float: left;
						@include media-breakpoint-down(sm) {
							height: $r-row-h-sm;
							line-height: $r-row-h-sm;
						}
						&.slot {
							width: $r-cell-w;
							time {
								font-size: $font-size-sm * 0.8;
								text-align: center;
								display: block;
								line-height: $r-row-h;
								transform: translateX(-(50%));
								// margin-left: -($r-cell-w / 2);
								// transform: translateX(-($r-cell-w / 2));
								@include media-breakpoint-down(sm) {
									line-height: $r-row-h-sm;
								}
							}
							&:nth-child(even) {
								time {
									opacity: 0.4;
								}
							}
						}
					}
					> button {
						border: none;
						padding: 0;
						background: transparent;
						line-height: 1.5;
						height: $r-row-h;
						position: relative;
						@include media-breakpoint-down(sm) {
							height: $r-row-h-sm;
						}
						&.reservation {
							cursor: pointer;
							cite {
								display: block;
								background-color: $success;
								color: $white;
								font-size: $font-size-sm;
								font-style: normal;
								padding: 0 $spacer-xxs;
								height: $r-row-h;
								display: flex;
								align-items: center;
								overflow: hidden;
								@include media-breakpoint-down(sm) {
									height: $r-row-h-sm;
									font-size: $font-size-sm * 0.7;
									line-height: 1.2;
								}
								b {
									font-weight: $font-weight-normal;
									display: block;
									// @include text-truncate();
									line-height: 1.3;
									width: 100%;
								}
							}
							i.mi {
								font-size: 12px;
								color: $white;
								position: absolute;
								top: 1px;
								right: 1px;
							}
							&:hover,
							&:active,
							&:focus {
								outline-style: none;
								cite {
									background-color: darken($success, 8%);
								}
							}
							&.pending {
								cite {
									background-color: $warning;
								}
								&:hover,
								&:active,
								&:focus {
									cite {
										background-color: darken($warning, 8%);
									}
								}
							}
						}
					}
				}
			}
			> header {
				> .r {
					> * {
						&.slot {
							// padding-right: $r-padd;
							time {
								// background-color: $gray-600;
								color: $gray-800;
							}
						}
					}
				}
			}
			> section {
				> .r {
					> * {
						&.slot {
							position: relative;
							// padding-right: $r-padd;
							&:hover,
							&:focus,
							&:active {
								&:before {
									// background-color: $gray-300;
								}
							}
							&:before {
								content: ' ';
								display: block;
								background-color: $gray-100;
								height: $r-row-h;
								// @include border-radius($border-radius);
								background-color: $white;
								@include media-breakpoint-down(sm) {
									height: $r-row-h-sm;
								}
							}
							&:after {
								content: ' ';
								display: block;
								width: 1px;
								height: $r-row-h;
								position: absolute;
								top: 0;
								left: 0;
								background-color: $gray-500;
								@include media-breakpoint-down(sm) {
									height: $r-row-h-sm;
								}
							}
						}
					}
				}
			}
		}
	}
	&.has-seats {
		> div {
			padding-left: $seat-w;
			@include media-breakpoint-down(sm) {
				padding-left: $seat-w-sm;
			}
			> div.tables {
				width: $seat-w;
				@include media-breakpoint-down(sm) {
					width: $seat-w-sm;
				}
				> section {
					> .r {
						strong {
							@include font-size($font-size-sm);
							display: block;
							@include text-truncate();
							padding: 0 $spacer-xs;
						}
					}
				}
			}
		}
	}
}
