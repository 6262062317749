aside.empty {
	text-align: center;
	padding: $spacer-xxxl $spacer-xxl;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	justify-content: center;
	// margin-top: -$spacer-xxl;
	@include media-breakpoint-down(md) {
		padding: $spacer-xl $spacer-lg;
	}
	@include media-breakpoint-down(sm) {
		padding: $spacer;
	}
	> * {
		width: 100%;
	}
	> i {
		font-size: 96px;
		line-height: 140px;
		height: 140px;
		width: 140px;
		background-color: $gray-400;
		color: $body-bg;
		@include border-radius(50%);
		@include media-breakpoint-down(md) {
			font-size: 80px;
			line-height: 110px;
			height: 110px;
			width: 110px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 60px;
			line-height: 90px;
			height: 90px;
			width: 90px;
		}
	}
	> h3 {
		margin: $spacer 0;
		width: 100%;
		@include font-size($h2-font-size * 0.9);
		color: $gray-500;
		font-weight: $font-weight-normal;
		@include media-breakpoint-down(md) {
			@include font-size($h2-font-size * 0.7);
		}
		@include media-breakpoint-down(sm) {
			@include font-size($h2-font-size * 0.5);
		}
	}
}

.header-ui {
	margin: $spacer-lg auto;
	padding: 0 $spacer-lg;
	> div {
		padding: $spacer-md $spacer-xl $spacer-lg;
		background-color: lighten($primary, 38%);
		border-radius: $border-radius;
		overflow: hidden;
		max-width: 720px;
		margin: 0 auto;
		h2 {
			color: $primary;
			line-height: 1;
		}
		> * {
			margin: $spacer-sm 0 0 0;
		}
	}
}

div.table-ui {
	width: 100%;
	margin: $spacer-lg 0;
	padding: 0 $spacer-lg;
	@include media-breakpoint-down(md) {
		padding: 0 $spacer-sm;
		margin: $spacer-sm 0;
	}
	> div {
		width: 100%;
		.archive-filters {
			display: flex;
			flex-wrap: wrap;
			padding-bottom: $spacer-sm;
			> * {
				min-width: 240px;
				padding-right: $spacer-sm;
				&:last-child {
					padding-right: 0;
					@include media-breakpoint-down(sm) {
						padding-bottom: 0;
					}
				}
				@include media-breakpoint-down(md) {
					width: 33.3264%;
					min-width: 0;
				}
				@include media-breakpoint-down(sm) {
					width: 100%;
					padding-right: 0 !important;
					padding-bottom: $spacer-xs;
				}
			}
			> .form-group {
				margin-top: 0;
				padding-left: 0;
				> .form-row {
					background-color: $white;
				}
				input[type='text'] {
					font-size: 0.9rem;
				}
			}
		}
		> table {
			width: 100%;
			min-width: 800px;
			margin-bottom: $spacer;
			color: $table-color;
			vertical-align: $table-cell-vertical-align;
			background-color: $table-bg;
			@include media-breakpoint-down(sm) {
				display: block;

				width: 100%;
				min-width: 100%;
			}
			thead {
				@include media-breakpoint-down(sm) {
					display: none;
				}
				&.thead-sep {
					@include media-breakpoint-down(sm) {
						display: block;
						width: 100%;
						margin-bottom: $spacer-sm;
					}
					tr,
					th {
						@include media-breakpoint-down(sm) {
							display: block;
						}
					}
				}
			}
			tbody {
				@include media-breakpoint-down(sm) {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}
			}
			// tr {
			// 	@include media-breakpoint-down(sm) {
			// 		display: block;
			// 		position: relative;
			// 		// max-width: 320px;
			// 		width: 31.3264%;
			// 		margin-bottom: $spacer;
			// 		// float: left;
			// 	}
			// 	@include media-breakpoint-down(xs) {
			// 		display: block;
			// 		// min-width: 260px;
			// 		width: 50%;
			// 		margin-left: auto;
			// 		margin-right: auto;
			// 	}
			// }
			th,
			td {
				padding: $spacer $spacer-xs;
				border-bottom: $table-border-width solid $table-border-color;
				@include media-breakpoint-down(sm) {
					display: block;
					text-align: center;
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
				p,
				span.img {
					@include media-breakpoint-down(sm) {
						margin-left: auto;
						margin-right: auto;
					}
				}
				&.center {
					text-align: center;
					p,
					span.img {
						margin-left: auto;
						margin-right: auto;
					}
				}
				&.right {
					text-align: right;
				}
				&:first-child {
					@include border-left-radius($border-radius);
					padding-left: $spacer-lg;
					@include media-breakpoint-down(md) {
						@include border-left-radius(0);
						padding-left: $spacer-md;
					}
				}
				&:last-child {
					@include border-right-radius($border-radius);
					@include media-breakpoint-down(md) {
						@include border-right-radius(0);
					}
				}
				&.color {
					padding: 0;
				}
			}

			// tbody {
			// 	vertical-align: inherit;
			// }

			thead th {
				vertical-align: bottom;
				background-color: $white;
				button {
					display: block;
					width: 100%;
					text-align: left;
					border: none;
					background: transparent;
					padding: 0;
				}
				&.center {
					button {
						text-align: center;
					}
				}
				&.spacer {
					background: transparent;
				}
				&.subhead {
					background-color: darken($gray-200, 4%);
					@include box-shadow($box-shadow-sm);
					@include media-breakpoint-down(md) {
						@include border-radius($border-radius-md);
					}
					&.active {
						background-color: lighten($primary, 32%);
					}
					span {
						display: inline-block;
						vertical-align: middle;
						padding: 0 $spacer-sm;
						color: $gray-600;
						b {
							color: $gray-900;
							font-weight: bolder;
						}
						&.badge-dark {
							color: $white;
							background-color: $gray-600;
							@include border-radius($border-radius-sm);
							b {
								color: $white;
							}
						}
						&.badge-primary {
							color: $white;
							@include border-radius($border-radius-sm);
							b {
								color: $white;
							}
						}
					}
				}
			}

			thead {
				th,
				button {
					color: $headings-color;
					@include font-size($font-size-sm);
					text-transform: uppercase;
				}
			}

			tbody + tbody {
				border-top: (2 * $table-border-width) solid $table-border-color;
			}

			tbody {
				&:after {
					@include media-breakpoint-down(sm) {
						content: ' ';
						display: block;
						clear: both;
					}
				}
				@include media-breakpoint-down(sm) {
					// display: block;
					// margin-bottom: $spacer;
				}
				tr {
					@include media-breakpoint-down(sm) {
						margin-bottom: $spacer;
						padding: $spacer;
						padding-bottom: $spacer-sm;
						background-color: $white;
						@include box-shadow($box-shadow-sm);
						width: 48%;
						margin-left: 1%;
						margin-right: 1%;
						position: relative;
					}
					@include media-breakpoint-down(xs) {
						margin-bottom: $spacer-sm;
						@include border-radius($border-radius-sm);
						width: 100%;
						margin-left: 0;
						margin-right: 0;
					}
					&.clickable {
						cursor: pointer;
					}
					&.strike {
						background-image: url(../../img/stripes-light.png);
						background-position: left bottom;
						background-color: $white;
						// background-color: lighten($green, 37%);
						&:hover {
							// background-color: lighten($green, 32%);
							@include media-breakpoint-down(sm) {
								// background-color: $white;
							}
						}
					}
					&:hover {
						background-color: $gray-200;
						@include media-breakpoint-down(sm) {
							background-color: $white;
						}
					}
					&.sep {
						text-align: left;
						@include media-breakpoint-down(sm) {
							padding: 0;
							margin-bottom: 0;
						}
						&:hover {
							background-color: transparent;
						}
						td {
							padding: 0;
							padding-top: $spacer-xl;
							@include media-breakpoint-down(sm) {
								padding-top: 0;
								margin-top: $spacer-lg;
							}
						}
						&:first-child {
							td {
								padding-top: $spacer-sm;
								@include media-breakpoint-down(sm) {
									padding-top: 0;
								}
							}
						}
						h4 {
							background-color: $gray-400;
							color: color-yiq($gray-400);
							display: flex;
							justify-content: space-between;
							padding: $spacer-sm $spacer-md $spacer-xs;
							@include border-top-radius($border-radius-md);
							align-items: center;
							margin-right: 0;
							@include media-breakpoint-down(sm) {
								margin-right: 0;
								display: block;
								padding: $spacer-sm $spacer-xs $spacer-xs;
							}
							> div {
								&.buttons {
									@include media-breakpoint-down(sm) {
										padding-top: $spacer-xs;
									}
									> .button {
										&:not(:first-child) {
											margin-left: $spacer-sm;
											@include media-breakpoint-down(sm) {
												margin-left: $spacer-xs;
											}
										}
									}
								}
							}
						}
						@each $color, $value in $theme-colors {
							&.#{$color} {
								h4 {
									background-color: $value;
									color: color-yiq($value);
								}
							}
						}
					}
					&.color-border {
						@include media-breakpoint-down(sm) {
							margin-bottom: 0;
							border-left: 4px $gray-400 solid;
							border-right: 4px $gray-400 solid;
							border-radius: 0;
						}
						td.color {
							background-color: $gray-400;
							border-radius: 0 !important;
						}
						&.last-item {
							border-bottom: $gray-400 2px solid;
						}
						@each $color, $value in $theme-colors {
							&.#{$color} {
								@include media-breakpoint-down(sm) {
									border-left-color: $value;
									border-right-color: $value;
								}
								td.color {
									background-color: $value;
									border-radius: 0 !important;
								}
								&.last-item {
									border-bottom: $value 2px solid;
								}
							}
						}
					}
				}
			}

			td {
				padding: $spacer-md $spacer-xs;
				vertical-align: middle;
				@include media-breakpoint-down(md) {
					padding: $spacer-xs $spacer-xs;
				}
				@include media-breakpoint-down(sm) {
					padding-top: 0;
				}
				&.email-cell {
					&.no-email {
						color: $red;
						font-weight: $font-weight-bold;
						animation: blinker 1s linear infinite;
					}
				}
				&.more-cell {
					@include media-breakpoint-down(sm) {
						position: absolute;
						top: $spacer-xxs;
						right: 0;
						margin: 0;
						padding: 0;
					}
				}
				&:not(.more-cell) {
					@include media-breakpoint-down(sm) {
						width: 100% !important;
					}
					> .button {
						margin-left: auto;
						margin-right: auto;
						&.button-done {
							width: 100% !important;
						}
					}
				}
				p {
					margin: 0;
					max-width: 240px;
					&.likes {
						> * {
							display: inline-block;
							vertical-align: middle;
						}
						> i {
							font-size: 12px;
							color: $red;
						}
					}
				}
				strong {
					color: $headings-color;
				}
				span.badge {
					display: inline-block;
					@include font-size($font-size-sm);
					font-weight: $font-weight-bold;
					min-width: $spacer-lg;
					padding: 0 $spacer-xs;
					line-height: $spacer-lg;
					@include border-radius($border-radius);
					text-align: center;
					&.badge-danger {
						background-color: $danger;
					}
					&.badge-success {
						background-color: $success;
					}
					&.badge-warning {
						background-color: $warning;
					}
				}
				pre.chrono {
					margin: 0;
					font-weight: bold;
					@include font-size($font-size-lg);
				}
				p.id {
					max-width: 220px;
					overflow: hidden;
					overflow-wrap: break-word;
					display: block;
					margin: 0;
					overflow-wrap: break-word;
					word-wrap: break-word;
					font-family: $font-family-monospace;
					@include font-size($font-size-sm);
					padding-right: 20px;
				}
				i.spot {
					width: $spacer-sm;
					height: $spacer-sm;
					@include border-radius(50%);
					background-color: $gray-400;
					display: block;
				}
				div.qty-control {
					display: flex;
					> * {
						background-color: $white;
						border: none;
						height: $spacer-xl;
						line-height: $spacer-xl;
						@include box-shadow($box-shadow-sm);
						&:first-child {
							@include border-left-radius($border-radius);
						}
						&:last-child {
							@include border-right-radius($border-radius);
						}
					}
					> p {
						padding: 0 $spacer-xs;
						@include font-size($font-size-sm);
						font-weight: $font-weight-bold;
					}
					> button {
						color: $body-color;
					}
				}
				&.center {
					div.qty-control {
						justify-content: center;
					}
				}
				*.inline-items {
					display: flex;
					justify-content: space-between;
				}

				div.allergens {
					img {
						width: $spacer-xl;
						height: $spacer-xl;
						display: inline-block;
					}
				}
				p.date {
					display: inline;
					margin: 0;
					max-width: 200px;
					> * {
						display: inline;
						vertical-align: middle;
					}
					> i {
						color: $gray-400;
					}
				}
				div.additions {
					// max-width: 240px;
					b {
						@include font-size($font-size-sm);
					}
					ul {
						list-style: none;
						margin: 0;
						padding: 0;
						li {
							display: inline;
							&:after {
								content: ', ';
							}
							&:last-child {
								&:after {
									content: '.';
								}
							}
						}
					}
				}
				ol {
					list-style: none;
					margin: 0;
					padding: 0;
					> li {
						padding: 0.3rem 0;
						display: inline;
						&:after {
							content: ', ';
							display: inline;
						}
						&:last-child {
							&:after {
								content: '.';
							}
						}
					}
				}
				&.custom-notes {
					padding: 0;
				}
			}
		}
	}
	&.table-sm {
		> div {
			max-width: map-get($grid-breakpoints, 'sm');
			margin-left: auto;
			margin-right: auto;
		}
	}
	&.table-ui-groups {
		> div {
			> table {
				tbody {
					tr {
						@include media-breakpoint-down(sm) {
							float: none;
							margin-left: auto;
							margin-right: auto;
							width: 100%;
						}
						&.sep {
							td {
								@include media-breakpoint-down(sm) {
									margin-top: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

// I moved the .more so it works outside a <td/>

div.more {
	position: relative;
	text-align: right;
	> button {
		padding: 0;
		border: none;
		background: transparent;
		color: $gray-500;
		width: $spacer-xl;
		height: $spacer-xl;
		@include transition(all 0.3s ease-out);
		outline-style: none;
		@include border-top-radius($border-radius-sm);
		i {
			line-height: $spacer-xl;
		}
		&:focus,
		&:active {
			outline-style: none;
			color: $gray-700;
		}
	}
	.dropdown {
		position: absolute;
		top: $spacer;
		opacity: 0;
		right: 0;
		width: 160px;
		background-color: $white;
		padding: $spacer-xs;
		z-index: 9;
		@include box-shadow($box-shadow);
		@include transition(all 0.3s ease-out);
		@include border-bottom-radius($border-radius-sm);
		@include border-left-radius($border-radius-sm);
		button {
			display: block;
			width: 100%;
			border: none;
			padding: $spacer-xs 0;
			background: transparent;
			text-align: left;
			&:focus,
			&:active {
				outline-style: none;
				color: $gray-400;
				> i {
					color: $primary;
				}
			}
			&:not(:first-child) {
				border-top: $gray-200 1px solid;
			}
			> * {
				display: inline-block;
				vertical-align: middle;
			}
			> i {
				margin-right: $spacer-xxs;
				margin-left: $spacer-xs;
				color: $gray-500;
				& + span {
					margin-left: $spacer-xxs;
				}
			}
			> span {
				@include font-size($font-size-sm);
			}
		}
		form {
			> div {
				text-align: center;
				> input {
					width: 100%;
					max-width: 120px;
					margin-left: auto;
					margin-right: auto;
					text-align: center;
					border: none;
					border-bottom: $border-color 2px solid;
					&:focus,
					&:active {
						outline-style: none;
						border-bottom-color: $primary;
					}
				}
			}
		}
	}
	&.show {
		> button {
			background-color: $white;
			@include box-shadow($box-shadow);
		}
		.dropdown {
			top: $spacer-xl;
			opacity: 1;
		}
		&.close {
			> button {
				background-color: transparent;
			}
			.dropdown {
				opacity: 0;
				top: $spacer;
			}
		}
	}
}

.paper {
	padding: $spacer;
	background-color: $white;
	border: $gray-200 1px solid;
	@include border-radius($border-radius-md);
	@include box-shadow($box-shadow);
	text-align: center;
}

.indicators-ui {
	width: 100%;
	margin: $spacer-lg 0;
	padding: 0 $spacer-lg;
	@include media-breakpoint-down(md) {
		padding: 0 $spacer-sm;
		margin: $spacer-sm 0;
	}
	> div {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-left: -$spacer-sm;
		margin-right: -$spacer-sm;
		padding: 10px;
		> div {
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			text-align: center;
			padding: 0 $spacer-sm;
			margin: 0;
			> div {
				padding: $spacer;
				background-color: $white;
				border: $gray-200 1px solid;
				margin-top: $spacer-sm;

				@include border-radius($border-radius-md);
				@include box-shadow($box-shadow);
				text-align: center;
				p {
					@include font-size($h5-font-size);
					font-weight: $font-weight-bold;
					color: $gray-900;
				}
				> div {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-right: 20px;
					padding-left: 20px;
					border-bottom: 1px solid $gray-500;
					margin-bottom: 5px;
					> b {
						@include font-size($h6-font-size);
					}
				}
			}
		}
	}
}

@keyframes blinker {
	50% {
		opacity: 0.2;
	}
}
