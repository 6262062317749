.login-ui {
	width: 100%;
	height: 100%;
	overflow: auto;
	&.wide {
		> div {
			max-width: 750px;
		}
	}
	> div {
		width: 90%;
		max-width: 480px;
		margin: 0 auto;
		min-height: 100%;
		display: flex;
		align-items: center;

		.form-ui {
			width: 100%;
			margin: ($spacer-lg * 2) 0 $spacer-lg;
			@include media-breakpoint-down(sm) {
				margin: 0;
			}
			.checkout-summary {
				> div {
					display: block;
					label {
						text-align: center;
					}
					span.price {
						text-align: center;
					}
					b {
						min-width: auto;
						display: inline-block;
					}
				}
			}
			> footer {
				display: flex;
				flex-wrap: wrap;
				.form-group {
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
			}
		}
		@include media-breakpoint-down(md) {
			width: 100%;
			margin: 0 auto;
		}
		// .form {
		// 	h1 {
		// 		color: $headings-color;
		// 		@include font-size($h3-font-size);
		// 		text-align: center;
		// 	}
		// }
	}
	&.login-ui-lg {
		> div {
			max-width: 720px;
		}
	}
	&.pricing-ui {
		> div {
			max-width: map-get($grid-breakpoints, 'md');
			.form-ui {
				> footer {
					padding-top: 0;
				}
			}
		}
	}
}

.table-subscriptions {
	margin: $spacer-xl 0;
	th,
	td {
		padding-left: $spacer-md;
		padding-right: $spacer-md;
	}
	thead {
		tr {
			background: $primary linear-gradient(90deg, $primary, $cyan) repeat-x !important;
			// border-radius: 2rem;
			th {
				color: $white;
				font-family: $headings-font-family;
				font-style: $headings-font-style;
				font-weight: $headings-font-weight;
				line-height: $headings-line-height;
				font-size: $h4-font-size;
				padding-top: $spacer-md;
				padding-bottom: $spacer-md;
				text-align: center;
				text-transform: uppercase;
				width: 28%;
				&:first-child {
					width: 16%;
				}
			}
		}
	}
	tbody {
		th {
			text-align: right;
		}
		th,
		td {
			padding-top: $spacer;
			padding-bottom: $spacer;
			&.active {
				background-color: lighten($cyan, 27%);
			}
			&:nth-child(2) {
				&.active {
					background-color: lighten($cyan, 31%);
				}
			}
			&:nth-child(4) {
				&.active {
					background-color: lighten($orange, 28%);
				}
			}
			small {
				font-size: $font-size-sm;
				display: block;
				font-weight: $font-weight-bold;
				color: $gray-500;
			}
			em {
				font-style: normal;
				// background-color: $gray-100;
				color: $green;
				font-weight: $font-weight-bold;
				display: inline-block;
				padding: 2px $spacer-xs;
				border-radius: 0.2em;
				border: lighten($green, 30%) 1px solid;
			}
			strong {
				color: $black;
			}
			span.price {
				display: inline-block;
				padding: 0 $spacer-sm;
				color: $primary;
				pre {
					font-size: $font-size-lg;
				}
			}
		}
		tr {
			border-bottom: $gray-300 2px solid;
		}
		td {
			text-align: center;
		}
	}
}

.form-ui {
	> .form-group {
		&.form-group-map {
			padding-left: 0;
			padding-right: 0;
			> div {
				border-bottom: none;
				padding: 0;
			}
		}
		&.form-group-schedule {
			> div {
				border: none;
				padding: 0;
				background: transparent;
				@include box-shadow(none);
				> label {
					text-align: center;
					@include media-breakpoint-down(sm) {
						margin-bottom: $spacer-xs;
					}
				}
				> div {
					> div {
						position: relative;
						padding-left: $input-check-size * 2.7;
						margin-bottom: $spacer-sm;

						> .input-check {
							position: absolute;
							top: 2px;
							left: 0;
						}
						> p {
							color: $headings-color;
							font-weight: $font-weight-bold;
							margin: 0;
							@include media-breakpoint-down(md) {
								@include font-size($font-size-sm);
							}
							button {
								background: transparent;
								border: none;
								background: transparent;
								@include font-size($font-size-sm);
								font-weight: $font-weight-bold;
								color: $primary;
								&:active,
								&:focus {
									outline-style: none;
									color: darken($primary, 10%);
								}
							}
						}
						> div {
							display: flex;
							flex-wrap: wrap;
							> p {
								margin-bottom: 0;
								width: 8%;
								text-align: center;
								color: $headings-color;
								font-weight: $font-weight-bold;
							}
							> .select {
								width: 23%;
								border-bottom: $gray-300 2px solid;
								@include media-breakpoint-down(sm) {
									width: 47%;
								}
							}
							> p,
							> .select {
								height: $spacer-xl;
								line-height: $spacer-xl;
								&:not(:first-child) {
									// margin-left: $spacer-xs;
								}
							}
							> p {
								@include media-breakpoint-down(sm) {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

div.location-map {
	> div {
		position: relative;
		.search-location {
			width: 94%;
			left: 3%;
			position: absolute;
			top: $spacer;
			z-index: 9;
			.form-group {
				input {
					background-color: $white;
					display: block;
					width: 100%;
					@include border-radius($border-radius-lg);
					@include box-shadow($box-shadow);
					padding: 0 $spacer;
					height: $spacer-xxl;
					line-height: $spacer-xxl;
				}
			}
		}
	}
	.map-embed {
		position: relative;
		width: 100%;
		height: 320px;
		.spinner {
			position: absolute;
			&.spinner2 {
				border-top-color: white;
				border-bottom-color: white;
			}
		}
	}
}
