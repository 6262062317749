.grid-ui {
	margin: $spacer-xl 0 0;
	padding: 0 $spacer-lg;
	@include media-breakpoint-down(sm) {
		padding: 0;
		margin: 0;
	}
	> div {
		> ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			padding: 0;
			// margin-right: -($spacer-xl / 2);
			// margin-left: -($spacer-xl / 2);
			list-style: none;
			justify-content: center;
			> li {
				width: 260px;
				padding: 0 ($spacer-xl / 2);
				margin-bottom: $spacer-xl;
				position: relative;
				@include media-breakpoint-down(sm) {
					margin-bottom: $spacer-sm;
				}
				> div:not([class]) {
					position: absolute;
					top: $spacer-xs;
					right: $spacer;
					@include media-breakpoint-down(sm) {
						right: 0;
					}
				}
			}
		}
	}
	&.grid-ui-horizontal {
		> div {
			> ul {
				@include media-breakpoint-down(sm) {
					margin-left: 0;
					margin-right: 0;
					display: block;
				}
				> li {
					width: 450px;
					@include media-breakpoint-down(sm) {
						padding: 0;
						width: 100%;
						margin-bottom: 0;
						max-width: 360px;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}
	}
}
