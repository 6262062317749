.popover {
	> div {
		background-color: $gray-900;
		color: $white;
		position: fixed;
		@include border-radius($border-radius-sm);
		padding: $spacer-xs $spacer-sm;
		max-width: 200px;
		z-index: 99;
		p {
			text-align: center;
			line-height: 1.5;
			margin: 0;
		}
		&:before {
			width: 10px;
			height: 10px;
			content: ' ';
			display: block;
			top: 14px;
			// margin-top: -3px;
			left: -10px;
			border: 5px $gray-900 solid;
			border-top-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
			position: absolute;
		}
	}
	&:hover {
		> i {
			color: $primary;
		}
	}
	&.right {
		> div {
			&:before {
				border: 5px $gray-900 solid;
				border-bottom-color: transparent;
				border-top-color: transparent;
				border-right-color: transparent;
				left: auto;
				right: -10px;
			}
		}
	}
}
