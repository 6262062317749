span.price {
	display: block;
	// color: $success;
	position: relative;
	// padding-right: $spacer-lg;
	small {
		display: inline-block;
		margin-right: $spacer-xs;
		color: $gray-600;
	}
	pre {
		margin: 0;
		font-weight: bold;
		@include font-size(0.9rem);
		display: inline-block;
		vertical-align: middle;
	}
	i {
		display: inline-block;
		vertical-align: middle;
		font-size: 18px;
		margin-left: 3px;
	}
	&.price-gray {
		color: $gray-500;
	}
	&.price-red {
		color: $red;
	}
	&.price-sm {
		pre {
			@include font-size(0.8rem);
		}
		i {
			font-size: 16px;
		}
	}
	&.price-lg {
		pre {
			@include font-size(1.3rem);
		}
		i {
			font-size: 22px;
		}
	}
}

span.img {
	width: $spacer-xxxl;
	height: $spacer-xxxl;
	display: block;
	background-color: $gray-300;
	@include border-radius(50%);
	position: relative;
	overflow: hidden;
	z-index: 1;
	@include background-cover();
	@include box-shadow($box-shadow-sm);
	i:not(mi) {
		width: 100%;
		height: 100%;
		display: block;
		@include background-cover();
	}
	i.mi {
		color: $gray-800;
	}
}

div.adds {
	padding-left: $spacer-xxxl + $spacer-sm;
	> em {
		font-style: normal;
		display: block;
		margin-top: 0;
		font-weight: $font-weight-bold;
	}
	> div {
		list-style: none;
		padding: 0;
		margin-top: $spacer-xs;
		> label {
			display: block;
			position: relative;
			padding-left: ($input-check-size * 2.55);
			cursor: pointer;
			> .input-check {
				position: absolute;
				top: 3px;
				left: 0;
				input {
					position: absolute;
				}
			}
			&.no-toggle {
				padding-left: 0;
				cursor: default;
				> p.add {
					b {
						&:before {
							content: '— ';
							display: inline;
						}
					}
				}
			}
			// > input {
			// 	position: absolute;
			// 	top: $spacer-xxs;
			// 	left: 0;
			// 	&:checked {
			// 		& + p.add {
			// 			color: $body-color;
			// 			span.price.price-gray {
			// 				color: $body-color;
			// 			}
			// 		}
			// 	}
			// }
			> p.add {
				margin: 0;
				display: flex;
				justify-content: space-between;
				// color: $gray-400;
				span.price.price-gray {
					color: $body-color;
					i {
						color: $gray-500;
					}
				}
				b {
					font-weight: $font-weight-normal;
					@include media-breakpoint-down(sm) {
						@include font-size($font-size-sm);
						line-height: 1.4;
					}
				}
			}
		}
	}
}

.product-import-list {
	list-style: none;
	margin: 0;
	padding: 0;
	border-top: $gray-100 $border-width solid;
	> li {
		border-bottom: $gray-100 $border-width solid;
		padding: $spacer-xs $spacer;
		> div {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			position: relative;
			padding-left: 50px;
			justify-content: space-between;
			> .input-check {
				position: absolute;
				top: 50%;
				left: 0;
				margin-top: -10px;
				> input {
					position: absolute;
					top: 0;
					left: 0;
				}
				label {
					margin: 0;
				}
			}
			> label {
				display: block;
				margin: 0;
				> * {
					display: inline-block;
					vertical-align: middle;
					margin-right: $spacer;
				}
				> em {
					@include media-breakpoint-down(sm) {
						display: block;
						padding-left: $spacer-xxxl + $spacer;
						margin-top: -$spacer;
					}
					> * {
						display: inline-block;
						vertical-align: middle;
						line-height: 1;
					}
					> .mi {
						font-size: 16px;
						margin-left: $spacer-xs;
					}
					> b {
						font-weight: normal;
						font-size: $font-size-sm;
					}
				}
			}
			.form-group {
				margin-top: 0;
				padding-right: 0;
				padding-left: $spacer-sm;
				width: 220px;
				label:not(.button),
				input[type='number'] {
					line-height: $spacer-xxl;
				}
				label:not(.button) {
					@include media-breakpoint-down(sm) {
						line-height: 1;
					}
				}
				> .form-row.has-append {
					.append i {
						line-height: $spacer-xxl;
					}
				}
			}
		}
	}
}
